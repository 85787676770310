import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ReplaceTextPipe} from "fuse-core/pipes/replace-text.pipe";
import {TimeSlotToStringOptions} from "fuse-core/directives/time-slot-to-string/time-slot-to-string.options";
import {greetings} from "./greetings";

const TO_REPLACE_KEY = '{timeSlot}';

@Pipe({
    name: 'timeSlotToString'
})
export class TimeSlotToStringPipe implements PipeTransform {

    constructor(
        private translate: TranslateService,
        private replaceTextPipe: ReplaceTextPipe
    ) {
    }

    transform(value: string, options: TimeSlotToStringOptions = {language_key: null}): string {

        /**
         * Attention ! Ca doit être le meme code que dans la directive qui porte le même nom
         * TODO utiliser ce pipe dans la directive
          */
        const currentDate = new Date();
        const triggeredTimeSlot = Object.keys(greetings).find((timeSlot) =>
            this.isBetween(currentDate, timeSlot)
        );

        if (!triggeredTimeSlot || !value.includes(TO_REPLACE_KEY)) {
            return value;
        }

        const timeSlotKey = greetings[triggeredTimeSlot];
        const languageKey = options.language_key ? options.language_key : 'default';
        const timeSlotKeyWithLanguage = timeSlotKey.replace('{language_key}', languageKey);
        const keyTranslated = this.translate.instant(timeSlotKeyWithLanguage)

        return this.replaceTextPipe.transform(value, {[TO_REPLACE_KEY]: keyTranslated})
    }

    private isBetween(toTestDate: Date, timeSlot: string): boolean {
        const [from, to] = timeSlot
            .split('-')
            .map((rawTime) => this.getSameDateButTime(toTestDate, rawTime));
        return from < toTestDate && to > toTestDate;
    }

    private getSameDateButTime(targetDate: Date, time: string): Date {
        const cloneDate = new Date(targetDate.getTime());
        const [h, m, s] = time.split(':').map((str) => Number(str));
        cloneDate.setHours(h);
        cloneDate.setMinutes(m);
        cloneDate.setSeconds(s);
        return cloneDate;
    }
}
