export const featureAccess = {
    showTechnicalProblemMessage: true,
    showWebAppBtn: true, // show or not button webapp on mathia toolbar
    institutionsGroupAccess: false,
    translationAccess: {
        show: false,
        withFlag: true
    },
    feedbackMenu: {default: false, trainer: false},
    showContact: {default: false, trainer: true},
    feedbackMenuAsGar: {default: false, trainer: true}, // menu to send feedback // menu to send feedback
    showTeacherLink: true,
};
