import {Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {CollectionOptionsInterface, DataEntity} from 'octopus-connect';
import {CompassService} from 'fuse-core/services/compass.service';
import {ActivatedRoute, Router} from '@angular/router';
import {map, mergeMap, startWith, take, tap} from 'rxjs/operators';
import {combineLatest, Observable, shareReplay} from 'rxjs';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {FormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {CompassExploreEntity} from "fuse-core/services/compassExploreEntity";
import {CompassThemeEntity} from "fuse-core/services/compassThemeEntity";

enum filter {
    diagnostics = 'diagnostics',
    skills = 'skills',
    levels = 'levels',
    difficulty = 'difficulty',
    peda = 'peda',
    orga = 'orga',
    explore = 'explore',
}

@Component({
    selector: 'app-diagnostic',
    templateUrl: './compass-themes.component.html'
})

export class CompassThemesComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {
    private compassService = inject(CompassService);
    private router = inject(Router);
    private activatedRoute = inject(ActivatedRoute);

    public themes: CompassThemeEntity[] = [];
    public displayLoader = true;
    public separatorKeysCodes: number[] = [ENTER, COMMA];
    public filterCtrl = new FormControl();
    public entitiesFiltered: Observable<DataEntity[]>;
    public diagnostics: DataEntity[] = [];
    public diagnosticsFromApi: DataEntity[] = [];
    public skills: DataEntity[] = [];
    public skillsFromApi: DataEntity[] = [];
    public difficulty: DataEntity[] = [];
    public difficultyFromApi: DataEntity[] = [];
    public pedaFromApi: DataEntity[] = [];
    public peda: DataEntity[] = [];
    public orgaFromApi: DataEntity[] = [];
    public orga: DataEntity[] = [];
    public levelCtrl: FormControl = new FormControl();
    public levels: DataEntity[] = [];
    public levelsFromApi: DataEntity[] = [];
    public levelsFiltered: Observable<DataEntity[]>;
    public filterType: filter = filter.diagnostics;

    private exploreEntityId = this.activatedRoute.snapshot.params.id;
    public exploreEntity$ = this.compassService.loadExploreEntity(this.exploreEntityId).pipe(take(1), shareReplay(1));


    @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>;
    @ViewChild('levelInput') levelInput: ElementRef<HTMLInputElement>;

    ngOnInit() {

        this.levelCtrl.valueChanges.pipe(
            tap((value) => {
                if (value) {
                    this.loadThemes().subscribe();
                }
            })
        ).subscribe();

        this.exploreEntity$.pipe(
            mergeMap((exploreEntity) => {
                return combineLatest([
                    this.compassService.getEducationalLevels(),
                    this.compassService.loadDiagnostics(),
                    this.compassService.loadSkills(),
                    this.compassService.getDifficulties(),
                    this.compassService.getPedagoList(),
                    this.compassService.getOrganisationList(),
                    this.loadThemes(exploreEntity)
                ])
                    .pipe(
                        take(1),
                        tap(([levels, diagnostics, skills, difficulty, pedagoList, organisationList, themes]) => {
                            this.levelsFromApi = levels;
                            this.diagnosticsFromApi = diagnostics;
                            this.skillsFromApi = skills;
                            this.difficultyFromApi = difficulty;
                            this.pedaFromApi = pedagoList;
                            this.orgaFromApi = organisationList;
                            this.themes = themes;
                            this.entitiesFiltered = this.filterCtrl.valueChanges.pipe(
                                startWith(null),
                                map((item: DataEntity | null) => (item ? this._filter(item) : this[`${this.filterType}FromApi`].slice())),
                            );
                            this.levelsFiltered = this.levelCtrl.valueChanges.pipe(
                                startWith(null),
                                map((level: DataEntity | null) => (level ? this._filterLevel(level) : this.levelsFromApi.slice())),
                            );
                        }),
                        tap(() => {
                            this.displayLoader = false;
                        }),
                    )
            })
        )
            .subscribe()
    }

    /**
     * cette fonction charge les themes en fonction des filtres
     */
    private loadThemes(exploreEntity ?: CompassExploreEntity) {
        this.displayLoader = true;
        const filterOptions: CollectionOptionsInterface = {filter: {}, page: 1};
        if (exploreEntity?.get('diagnostic')) {
            filterOptions.filter['diagnostic'] = exploreEntity.get('diagnostics')
        } else if (this.diagnostics?.length > 0) {
            filterOptions.filter['diagnostic'] = this.diagnostics.map((diagnostic) => diagnostic.id);
        }
        if (exploreEntity?.get('skills')) {
            filterOptions.filter['skills'] = exploreEntity.get('skills')
        } else if (this.skills?.length > 0) {
            filterOptions.filter['skills'] = this.skills.map((skill) => skill.id);
        }
        if (exploreEntity?.get('difficulty')) {
            filterOptions.filter['difficulty'] = exploreEntity.get('difficulty')
        } else if (this.difficulty?.length > 0) {
            filterOptions.filter['difficulty'] = this.difficulty.map((difficulty) => difficulty.id);
        }
        if (exploreEntity?.get('peda')) {
            filterOptions.filter['peda'] = exploreEntity.get('peda')
        } else if (this.peda?.length > 0) {
            filterOptions.filter['peda'] = this.peda.map((pedago) => pedago.id);
        }
        if (exploreEntity?.get('orga')) {
            filterOptions.filter['orga'] = exploreEntity.get('orga')
        } else if (this.orga?.length > 0) {
            filterOptions.filter['orga'] = this.orga.map((organisation) => organisation.id);
        }
        if (exploreEntity?.get('levels')) {
            filterOptions.filter['levels'] = exploreEntity.get('levels')
        } else if (this.levelCtrl?.value?.id) {
            filterOptions.filter['levels'] = this.levelCtrl.value.id;
        }
        if (exploreEntity) {
            filterOptions.filter['explore'] = exploreEntity.id
        }
        return this.compassService.loadThemes(filterOptions)
            .pipe(
                take(1),
                tap(themes => this.themes = themes),
                tap(() => this.displayLoader = false)
            );
    }

    /**
     * cette fonction permet de voir les ressources d'un theme
     */
    public seeResources(theme: CompassThemeEntity): void {
        if (theme?.get('ressources')?.length > 0) {
            this.router.navigate(['boussole-ressources'],
                {
                    state: {
                        exploreEntityId: this.exploreEntityId,
                        id: theme?.get('ressources'),
                        selectedThemeTitle: theme.get('label'),
                        selectedThemeBody: theme.get('body'),
                        medias: theme.get('medias')
                    }
                });
        }
    }

    /**
     * alimente le filtre de recherche par entité
     * @param event
     */
    add(event: MatChipInputEvent) {
        const value = (event.value || '').trim();
        const entityMatching: DataEntity = this[this.filterType].find((item) => item.get('label') === value);
        // Add our entity
        if (entityMatching !== undefined) {
            this[this.filterType].push(entityMatching);
        }

        // Clear the input value
        event.chipInput!.clear();
        this.filterCtrl.setValue(null);
    }


    /**
     * alimente le filtre de recherche par niveau scolaire
     * @param event
     */
    addLevel(event: MatChipInputEvent) {
        const value = (event.value || '').trim();
        const levelMatching: DataEntity = this.levels.find((level) => level.get('label') === value);
        // Add our level
        if (levelMatching !== undefined) {
            this.levels.push(levelMatching);
        }

        // Clear the input value
        event.chipInput!.clear();

        this.levelCtrl.setValue(null);
    }

    /**
     * cette fonction permet de supprimer une entité dans la recherche
     */
    remove(entityToRemove: DataEntity) {
        const index = this[this.filterType].findIndex((item) => item.get('label') === entityToRemove.get('label'));

        if (index >= 0) {
            this[this.filterType].splice(index, 1);
        }
    }

    /**
     * cette fonction permet de supprimer un niveau scolaire dans la recherche
     * @param level
     */
    removeLevel(level: DataEntity) {
        const index = this.levels.findIndex((item) => item.get('label') === level.get('label'));

        if (index >= 0) {
            this.levels.splice(index, 1);
        }
    }

    /**
     * cette fonction permet de selectionner une entité dans la recherche
     */
    selected(event: MatAutocompleteSelectedEvent) {
        const entityMatching: DataEntity = this[`${this.filterType}FromApi`].find((entity) => entity.get('label') === event.option.viewValue);
        const entityAlreadySelected = this[this.filterType].find((entity) => entity.get('label') === event.option.viewValue);
        if (entityMatching !== undefined && entityAlreadySelected === undefined) {
            this[this.filterType].push(entityMatching);
        }

        this.filterInput.nativeElement.value = null;
        this.filterCtrl.setValue(null);
        this.loadThemes().subscribe();
    }

    /**
     * cette fonction permet de selectionner un niveau scolaire dans la recherche
     * @param event
     */
    selectedLevel(event: MatAutocompleteSelectedEvent) {
        const levelMatching: DataEntity = this.levelsFromApi.find((level) => level.get('label') === event.option.viewValue);
        const levelAlreadySelected = this.levels.find((level) => level.get('label') === event.option.viewValue);
        if (levelMatching !== undefined && levelAlreadySelected === undefined) {
            this.levels.push(levelMatching);
        }
        this.levelInput.nativeElement.value = null;
        this.levelCtrl.setValue(null);
        this.loadThemes().subscribe();
    }

    /**
     * cette fonction permet de filtrer les diagnostics dans le matAutocomplete
     * @param value
     * @private
     */
    private _filter(value: DataEntity): DataEntity[] {
        const filterValue = value ? value.get('label').toLowerCase() : '';
        return this[`${this.filterType}FromApi`].filter(item => item.get('label').toLowerCase().includes(filterValue));
    }

    /**
     * cette fonction permet de filtrer les niveaux scolaires dans le matAutocomplete
     * @param value
     * @private
     */
    private _filterLevel(value: DataEntity) {
        const filterValue = value ? value.get('label').toLowerCase() : '';
        return this.levelsFromApi.filter(level => level.get('label').toLowerCase().includes(filterValue));
    }

    /**
     * cette fonction permet de reinitialiser les filtres
     */
    public resetFilters() {
        this[this.filterType] = [];
        this.filterCtrl.setValue(null);
        this.diagnostics = [];
        this.skills = [];
        this.difficulty = [];
        this.levels = [];
        this.levelCtrl.setValue(null);
        this.loadThemes().subscribe();
    }

    public get entitiesForFilter(): DataEntity[] {
        return this[this.filterType] || [];
    }

    goBack() {
        this.router.navigate(['boussole']);
    }
}
