import {MergeTypes} from 'shared/merge';
import {FileGranuleAttributes, GranuleEntityAttributesInterface} from 'shared/models/granule';

export type MediaGranule = GranuleEntityAttributesInterface<FileGranuleAttributes>;

export type MediaContent = {
    granule: MediaGranule[],
    id: string | number,
    image: string,
    marker: unknown[],
    media: string
}

export type UpdatableMediaContent = Partial<MergeTypes<[
    Omit<MediaContent, 'id' | 'granule'>,
    {granule: (number|string)[]}
]>>;