import {Component, EventEmitter, Input, Output} from '@angular/core';
import {VoiceRecorderService} from '@modules/activities/core/services/voice-recorder.service';
import {FlashcardAttribute} from 'shared/flashcard';

type FlashCardInterface = {id: string} & FlashcardAttribute;

@Component({
    selector: 'app-dialog-resume',
    templateUrl: './dialog-resume.component.html',
    providers: [VoiceRecorderService]
})
export class DialogResumeComponent {
    incomingAudio: HTMLAudioElement;
    userAudio: HTMLAudioElement;
    public currentIndex = 0;
    public isPlayingUserAudios = false;
    public isPlayingOriginalAudios = false;
    public isPlayingUserAudio = false;
    public isPlayingOriginalAudio = false;
    public menuIndex = -1;
    public recordForSelectedElementExist = false;
    @Input('currentCard') currentCard: FlashCardInterface[] = [];
    @Input('unlockDialogDone') unlockDialogDone = false;
    @Input('allUserAudio') allUserAudio: { id: string, blob: Blob }[] = [];
    @Input('mainCardPicture') mainCardPicture = '';
    @Output() launch = new EventEmitter<boolean>();
    @Output() close = new EventEmitter<boolean>();
    @Output() record = new EventEmitter<number>();

    constructor(private voiceRecorderService: VoiceRecorderService) {

    }

    public launchActivity(): void {
        this.launch.emit(true);
    }

    public playOriginalAudio(): void {
        this.currentIndex = 0;
        this.isPlayingOriginalAudios = true;
        this.isPlayingUserAudios = false;
        this.pauseUserAudio();
        this.voiceRecorderService.state.incomingAudioUrl = this.currentCard[0].audio.uri.toString();
        const audioUrls = this.currentCard.map(card => card.audio.uri);
        let index = 0;

        const playNextAudio = () => {
            if (index < audioUrls.length && !this.isPlayingUserAudios) {
                this.incomingAudio = new Audio(audioUrls[index].toString());
                this.incomingAudio.play();
                this.incomingAudio.onended = () => {
                    index++;
                    this.currentIndex++;
                    playNextAudio();
                };
            } else {
                this.isPlayingOriginalAudios = false;
                this.currentIndex = 0;
            }
        };

        playNextAudio();
    }

    public playAllCurrentUserAudios(): void {
        this.currentIndex = 0;
        this.isPlayingUserAudios = true;
        this.isPlayingOriginalAudios = false;

        this.pauseOriginalAudio();
        const audioUrls = this.currentCardAudios().map(({audio, index}) => ({audio: URL.createObjectURL(audio), index: index}));
        let index = 0;

        const playNextAudio = () => {
            if (index < audioUrls.length && !this.isPlayingOriginalAudios) {
                this.userAudio = new Audio(audioUrls[index].audio);
                this.currentIndex = audioUrls[index].index;
                this.userAudio.play();
                this.userAudio.onended = () => {
                    index++;
                    this.currentIndex = audioUrls[index].index;
                    playNextAudio();
                };
            } else {
                this.isPlayingUserAudios = false;
                this.currentIndex = 0;
            }
        };

        playNextAudio();
    }

    private pauseOriginalAudio(): void {
        if (this.incomingAudio) {
            this.incomingAudio.pause();
        }
    }

    private pauseUserAudio(): void {
        if (this.userAudio) {
            this.userAudio.pause();
        }
    }

    public stop(): void {
        this.pauseOriginalAudio();
        this.pauseUserAudio();
        this.isPlayingOriginalAudio = false;
        this.isPlayingUserAudio = false;
        this.isPlayingOriginalAudios = false;
        this.isPlayingUserAudios = false;
    }

    private currentCardAudios(): { audio: Blob, index: number }[] {
        return this.currentCard.map((card, index) => {
            const userAudio = this.allUserAudio.find(audio => audio.id === card.id);
            if (userAudio) {
                return {audio: userAudio.blob, index: index};
            }
            return null;
        }).filter(audio => audio !== null);
    }

    public isPlaying(): boolean {
        return this.isPlayingUserAudios || this.isPlayingOriginalAudios || this.isPlayingUserAudio || this.isPlayingOriginalAudio;
    }

    public openMenu(index: number) {
        this.recordForSelectedElementExist = !!this.currentCardAudio(index);

        this.stop();
        if (this.menuIndex !== index) {
            this.menuIndex = index;
        } else {
            this.menuIndex = -1;
        }
    }

    public recordAgain(index: number) {
        this.record.emit(index);
    }

    public playOriginalVersion(index: number) {
        this.currentIndex = index;
        this.isPlayingOriginalAudio = true;
        this.isPlayingUserAudio = false;
        this.pauseUserAudio();
        const audioUrls = this.currentCard.map(card => card.audio.uri);
        if (!this.isPlayingUserAudio) {
            this.incomingAudio = new Audio(audioUrls[index].toString());
            this.incomingAudio.play();
            this.incomingAudio.onended = () => {
                this.isPlayingOriginalAudio = false;
            };
        }
    }

    public playUserRecord(index: number) {
        this.currentIndex = index;
        this.isPlayingUserAudio = true;
        this.isPlayingOriginalAudio = false;
        this.pauseOriginalAudio();
        const audioUrl = URL.createObjectURL(this.currentCardAudio(index));

        if (!this.isPlayingOriginalAudio && audioUrl) {
            this.userAudio = new Audio(audioUrl);
            this.userAudio.play();
            this.userAudio.onended = () => {
                this.isPlayingUserAudio = false;
            };
        }
    }

    private currentCardAudio(index: number): Blob {
        const cardId = this.currentCard[index].id;
        const userAudio = this.allUserAudio.find(audio => audio.id === cardId);
        if (userAudio) {
            return userAudio.blob;
        }
        return null;
    }


    public stopPlaying(index: number) {
        this.stop();
    }

    public almostOneUSerAudio(): boolean {
        return this.currentCardAudios().length > 0;
    }
}