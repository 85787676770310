// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: solid 1px black;
  height: 100%;
}

.color_0 {
  background-color: red;
}

.color_1 {
  background-color: green;
}

.color_2 {
  background-color: blue;
}

.color_3 {
  background-color: yellow;
}

.container {
  position: relative;
  width: 100%;
}

.image {
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  opacity: 0.8;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/activity-edition/core/ui/typologies-form/activity-edition-point-img/activity-edition-point-img-zone-generator/activity-edition-point-img-zone-generator.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,uBAAA;EACA,uBAAA;EACA,YAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,uBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,wBAAA;AACJ;;AAGA;EACI,kBAAA;EACA,WAAA;AAAJ;;AAGA;EACI,WAAA;EACA,YAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AAAJ","sourcesContent":[".grid-item {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: white;\n    border: solid 1px black;\n    height: 100%;\n}\n\n.color_0 {\n    background-color: red;\n}\n\n.color_1 {\n    background-color: green;\n}\n\n.color_2 {\n    background-color: blue;\n}\n\n.color_3 {\n    background-color: yellow;\n}\n\n\n.container {\n    position: relative;\n    width: 100%;\n}\n\n.image {\n    width: 100%;\n    height: auto;\n}\n\n.overlay {\n    position: absolute;\n    opacity: 0.8;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
