import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {get} from 'lodash-es';
import {NgAudioRecorderService, OutputFormat} from 'ng-audio-recorder';
import {VoiceRecorderService} from '@modules/activities/core/services/voice-recorder.service';
import {Subject, takeWhile, timer} from 'rxjs';
import {FlashcardAttribute} from 'shared/flashcard';

@Component({
    selector: 'app-voice-recorder',
    templateUrl: './voice-recorder.component.html',
    styleUrls: ['./voice-recorder.component.scss'],
    providers: [VoiceRecorderService, NgAudioRecorderService]
})
export class VoiceRecorderComponent implements OnInit, OnChanges {
    @Input() displayForSummary = false;
    @Input() flashcard: FlashcardAttribute;
    @Input() forcePauseIncomingAudio$?: Subject<void>;
    @Input() forceReset$?: Subject<void>;
    @Output() activityDone = new EventEmitter<void>();
    @Input() showTitle = false;
    @Output() userAudioBlobEvent = new EventEmitter<Blob>();// send the current record
    @Input() userAudioBlob: Blob;

    incomingAudio: HTMLAudioElement;
    userAudio: HTMLAudioElement;

    public recordTimer: string;
    public recordProgress: number;

    private recordingTimeout: any;
    private readTimeout: any;
    private endActivityTimeout: any;

    private playIncomingAudioTimeout: any;

    constructor(
        public audioRecorderService: NgAudioRecorderService,
        public service: VoiceRecorderService,
    ) {
    }

    ngOnInit(): void {
        this.initStates();
        if (this.userAudioBlob) {
            this.service.state.userAudioFileBlob = this.userAudioBlob;
        }
    }

    initialize(): void {
        this.service.setState({
            listenIncomingAudioButtonDisabled: false,
            listenUserRecordingDisabled: true,
            recordingButtonDisabled: true,
            userAudioFileBlob: null,
            image: {
                uri: this.flashcard?.image?.uri.toString() || '',
                title: get(this.flashcard, '.image.title') || '',
            }
        });
        this.service.setState({
            incomingAudioUrl: this.flashcard?.audio?.uri.toString() || '',
        });

        if (this.forcePauseIncomingAudio$) {
            this.forcePauseIncomingAudio$.subscribe(() => {
                this.pauseIncomingAudio();
            });
        }

        if (this.forceReset$) {
            this.forceReset$.subscribe(() => {
                this.reset(true);
            });
        }

        if (this.userAudioBlob) {
            this.service.state.userAudioFileBlob = this.userAudioBlob;
        }
    }

    private initStates() {
        if (this.displayForSummary) {
            this.service.setState({
                listenIncomingAudioButtonDisabled: false,
                listenUserRecordingDisabled: true,
                recordingButtonDisabled: true
            });
        } else {
            this.service.setState({
                listenIncomingAudioButtonDisabled: false,
                listenUserRecordingDisabled: true,
                recordingButtonDisabled: true,
                userAudioFileBlob: null
            });
        }
    }

    ngOnChanges(): void {
        this.initialize();
    }

    pauseIncomingAudio(): void {
        if (this.incomingAudio?.paused === false) {
            this.incomingAudio.pause();
        }
    }

    pauseUserAudio(): void {
        if (this.userAudio?.paused === false) {
            this.userAudio.pause();
        }
    }

    playIncomingAudio(): void {
        this.service.setState({recordingButtonDisabled: true, listenUserRecordingDisabled: true});
        this.incomingAudio = new Audio(this.service.state.incomingAudioUrl);
        this.incomingAudio.play();
        this.incomingAudio.ondurationchange = (ev) => {
            this.playIncomingAudioTimeout = setTimeout(() => {
                    this.service.setState({recordingButtonDisabled: false, listenUserRecordingDisabled: !this.service.state?.userAudioFileBlob});
                },
                this.totalTimeToWait());
        };
    }

    stopRecording(storeRecord: boolean = true): void {
        this.clearRecordingTimeout();

        this.service.setState({
            isRecording: false,
            listenIncomingAudioButtonDisabled: false,
            listenUserRecordingDisabled: false,
        });

        this.audioRecorderService
            .stopRecording(OutputFormat.WEBM_BLOB)
            .then((output: Blob) => {
                if (storeRecord) {
                    this.service.setState({userAudioFileBlob: output});
                    this.userAudioBlobEvent.emit(output);
                }
            })
            .catch((errrorCase) => {
                //         Handle Error
            });

        this.recordTimer = '';
        this.recordProgress = 0;
    }

    startRecording(): void {
        this.pauseIncomingAudio();
        this.audioRecorderService.startRecording();
        this.service.setState({
            isRecording: true,
            listenIncomingAudioButtonDisabled: true,
            listenUserRecordingDisabled: true
        });
        this.startRecordingTimer();

        this.recordingTimeout = setTimeout(() => {
            this.stopRecording();
        }, this.totalTimeToWait());
    }

    private startRecordingTimer() {
        let startTime = Date.now();
        {
        }
        timer(0, 50)
            .pipe(
                takeWhile(() => this.service.state.isRecording),
            )
            .subscribe(() => {
                let timerMilliseconds = (Date.now() - startTime);
                let timerSeconds = Math.floor(timerMilliseconds / 1000);
                this.recordTimer = `${Math.floor(timerSeconds / 60).toString().padStart(2, '0')}:${(timerSeconds % 60).toString().padStart(2, '0')}`;
                this.recordProgress = (timerMilliseconds / this.recorderTime()) * 100;
            });
    }

    private clearRecordingTimeout(): void {
        if (this.recordingTimeout) {
            clearTimeout(this.recordingTimeout);
            this.recordingTimeout = null;
        }
    }

    compareAudio(): void {
        this.pauseIncomingAudio();
        this.service.setState({
            listenIncomingAudioButtonDisabled: true,
            recordingButtonDisabled: true,
        });
        if (this.service.state.userAudioFileBlob instanceof Blob) {
            try {
                const userAudioUrl = URL.createObjectURL(this.service.state.userAudioFileBlob);
                this.userAudio = new Audio(userAudioUrl);
                // 1 read original audio
                this.userAudio.play();
                // 2 read original audio only after reading recorded audio
                this.readTimeout = setTimeout(() => {
                    this.incomingAudio.currentTime = 0;
                    this.incomingAudio.play();
                    // 3 enable button only after read of original audio finish
                    this.endActivityTimeout = setTimeout(() => {
                        this.service.setState({
                            listenIncomingAudioButtonDisabled: false,
                            listenUserRecordingDisabled: false,
                            recordingButtonDisabled: false,
                        });
                        this.activityDone.emit(); // activity done only after listening both audio
                    }, (this.incomingAudio.duration * 1000) + 500); // 500 ms second more than real time audio
                }, this.totalTimeToWait());
            } catch (error) {
                this.activityDone.emit();
                console.error('Erreur lors de la création de l\'URL pour userAudioFileBlob', error);
            }
        } else {
            this.activityDone.emit();
            console.error('userAudioFileBlob n\'est pas un Blob ou est undefined/null');
        }

    }

    /**
     * record time is time of audio + extra time for moment 3 seconds
     */
    public recorderTime(): number {
        if (this.incomingAudio.duration) {
            return (this.incomingAudio.duration + this.extraTimeToRecord()) * 1000;
        } else {
            return 7000;
        }
    }

    /**
     * add 1sec of extra time to record for each five sec
     * @private
     */
    private extraTimeToRecord(): number {
        if (this.incomingAudio.duration) {
            return 1 + Math.round(this.incomingAudio.duration / 5);
        } else {
            return 2;
        }
    }

    /**
     * time of audio + extra time security
     * @private
     */
    private totalTimeToWait(): number {
        return (this.incomingAudio.duration + this.extraTimeToRecord()) * 1000;
    }

    ngOnDestroy(): void {
        this.reset();
    }

    private reset(initialize?: boolean): void {
        // clear all timeout we navigate on another activity
        if (this.recordingTimeout) {
            clearTimeout(this.recordingTimeout);
        }
        if (this.readTimeout) {
            clearTimeout(this.readTimeout);
        }
        if (this.endActivityTimeout) {
            clearTimeout(this.endActivityTimeout);
        }
        if (this.playIncomingAudioTimeout) {
            clearTimeout(this.playIncomingAudioTimeout);
        }

        // stop all current audio
        this.pauseIncomingAudio();
        this.pauseUserAudio();
        this.stopRecording(false);
        this.service.resetState();
        this.initStates();
        if (initialize) {
            this.initialize();
        }
    }
}
