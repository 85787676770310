import {AnswerAppInterface, AnswerInterface} from '@modules/activities/core/models/answer.interface';
import {MergeTypes} from 'shared/merge';
import {PartialExcept} from 'shared/utils/partial.except';

export interface AnswersContent {
    id: string | number;
    answer_text?: string;
    answers: AnswerInterface[];
    answers_app?: AnswerAppInterface[];
}

export type UpdatableAnswersContent = PartialExcept<MergeTypes<[
    Omit<AnswersContent, 'id' | 'answers' | 'answers_app'>,
    {answers: number[], answers_app: number[]}
]>, 'answers'>;