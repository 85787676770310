import {inject, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IsUserLogged} from '../../../../guards/is-user-logged.class';
import {RouterModule, Routes} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {LessonsComponent} from '@modules/activities/core/lessons/lessons.component';
import {DynamicNavigationService} from '../../../../navigation/dynamic-navigation.service';
import {DataEntity} from 'octopus-connect';
import {LessonsListComponent} from './lessons-list/lessons-list.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyPaginatorIntl as MatPaginatorIntl} from '@angular/material/legacy-paginator';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {CustomMatPaginatorIntl} from 'fuse-core/matPaginatorI18n';
// import {FormsListComponent} from '@modules/activities/core/lessons/forms-list/forms-list.component';
import {LessonsTabComponent} from '@modules/activities/core/lessons/lessons-list/lessons-tab/lessons-tab.component';
import {FlagService} from 'shared/flag.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {PlayersModule} from '@modules/activities/core/player-components/players.module';
import {FormPlayerComponent} from './lessons-list/forms-tab/form-player/form-player.component';
// import {ResolverForFormPlayer} from '@modules/activities/core/lessons/resolvers/formPlayer.resolver';
// import {EditFormDialogComponent} from './forms-list/edit-form-dialog/edit-form-dialog.component';
// import {FormActivitiesComponent} from './forms-list/form-activities/form-activities.component';
import {DragulaModule} from 'ng2-dragula';
import {MatStepperModule} from '@angular/material/stepper';
import {ActivitiesDirectiveModule} from '@modules/activities/core/directive/activities-directive.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {EditLessonDialogComponent} from './lessons-list/edit-lesson-dialog/edit-lesson-dialog.component';
import {LessonActivitiesComponent} from './lessons-list/lesson-activities/lesson-activities.component';
import {SequencesTabComponent} from '@modules/activities/core/lessons/lessons-list/sequences-tab/sequences-tab.component';
import {ActivitiesListComponent} from '@modules/activities/core/activities-list/activities-list.component';
import {ActivitiesHomeComponent} from '@modules/activities/core/activities-home/activities-home.component';
import {LessonActivitiesResolver} from '@modules/activities/core/lessons/resolvers/lesson-activities.resolver';
import {ActivitiesSharedModule} from '@modules/activities/core/activities-shared.module';
import {CorpusDisplayEmbedComponent} from '@modules/activities/core/shared-components/corpus-display-embed/corpus-display-embed.component';
import {LessonsRecapComponent} from '@modules/activities/core/lessons/lessons-recap/lessons-recap.component';
import {FuseLessonsModule} from 'fuse-core/components/lessons-selection/fuse-lessons.module';
import {LessonMetadataDialogComponent} from './lessons-list/lesson-metadata-dialog/lesson-metadata-dialog.component';
import {LessonsFavoritesComponent} from './widget/lessons-favorites/lessons-favorites.component';
import {CommonSharedModule} from '@modules/activities/core/shared-components/common-shared.module';
import {EditLessonComponent} from '@modules/activities/core/lessons/lessons-list/edit-lesson/edit-lesson.component';
import {OnlyLessonLessonsListComponent} from './lessons-list/only-lesson-lessons-list/only-lesson-lessons-list.component';
import {OnlyModelLessonsListComponent} from './lessons-list/only-model-lessons-list/only-model-lessons-list.component';
import {CanShowLessonListGuard} from '@modules/activities/core/lessons/lessons-list/can-show-lesson-list.guard';
import {SummaryComponent} from '@modules/activities/core/shared-components/summary/summary.component';
import {LessonsConsultedComponent} from './widget/lessons-consulted/lessons-consulted.component';
import {LessonsCreatedComponent} from './widget/lessons-created/lessons-created.component';
import {VideoEditorComponent} from '@modules/activities/core/editor-components/video-editor/video-editor.component';
import {ShortAnswerMultiEditorComponent} from '@modules/activities/core/editor-components/short-answer-multi-editor/short-answer-multi-editor.component';
import {EditorsModule} from '@modules/activities/core/editor-components/editors.module';
import {NgxFileDropModule} from 'ngx-file-drop';
import {CommunityListComponent} from '@modules/activities/core/lessons/lessons-list/community-list/community-list.component';
import {ThemesModule} from '@modules/activities/core/themes/themes.module';
import {SearchFiltersModule} from 'fuse-core/components';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {DeleteLessonWarningComponent} from './lessons-list/lessons-tab/delete-lesson-warning/delete-lesson-warning.component';
import {EditLessonWarningComponent} from './lessons-list/lessons-tab/edit-lesson-warning/edit-lesson-warning.component';
import {CanLaunchOldEditorGuard} from '@modules/activities/core/lessons/editor/guards/can-launch-old-editor.guard';
import {ProgressBarGenericModule} from '@fuse/components/progress-bar-generic/progress-bar-generic.module';
import {NgChartsModule} from 'ng2-charts';
import {RecommendationComponent} from './lessons-list/recommendation/recommendation.component';
import {LauncherComponent} from './lessons-list/launcher/launcher/launcher.component';
import {LauncherButtonComponent} from './lessons-list/launcher/launcher-button/launcher-button.component';
import {LessonsInChaptersListComponent} from '@modules/activities/core/activities-home/lessons-in-chapters-list/lessons-in-chapters-list.component';
import {RewardPageComponent} from '@modules/activities/core/reward-page/reward-page.component';
import {SummarySubLessonComponent} from './summary-sub-lesson/summary-sub-lesson.component';
import {ActivitiesPipesModule} from '@modules/activities/core/pipes/activitiesPipes.module';
import {LessonAuthorizationService} from '@modules/activities/core/lessons/services/lesson-authorization.service';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {takeUntil, map, mergeMap, tap, startWith} from 'rxjs/operators';
import {combineLatest, Observable, Subject} from 'rxjs';
import {AuthorizationService} from '@modules/authorization';
import {AsyncRules} from '@modules/activities/core/models/rules';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {ListOfActivitiesComponent} from './list-of-activities/list-of-activities.component';
import {LessonPageComponent} from './lesson-page/lesson-page.component';
import {
    ButtonListDialogComponent
} from 'fuse-core/components/button-list/button-list-dialog/button-list-dialog.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {FuseNavigationItem} from 'fuse-core/types';
import {MatDialogModule} from '@angular/material/dialog';
import {PeriodListComponent} from '@modules/activities/core/activities-home/period-list/period-list.component';
import {UsageEntity, UsagesService} from '@modules/activities/core/services/usages.service';
import {UserLessonsComponent} from '@modules/activities/core/lessons/lessons-list/user-lessons/user-lessons.component';
import { AddLessonButtonMorphableComponent } from './lessons-list/lessons-tab/add-lesson-button-morphable/add-lesson-button-morphable.component';
import { OldSchoolComponent } from './lessons-list/lessons-tab/add-lesson-button-morphable/button-types/old-school/old-school.component';
import { NeoComponent } from '@modules/activities/core/lessons/lessons-list/lessons-tab/add-lesson-button-morphable/button-types/neo/neo.component';
import {UserReviewModule} from "fuse-core/components/user-review/user-review.module";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
// import { ContentModule } from 'app/layout/components/content/content.module';

const routes: Routes = [
    {
        path: 'lessons',
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'home',
                component: ActivitiesHomeComponent,
            },
            {
                path: 'grade/:gradeId/concept/:conceptId',
                component: PeriodListComponent,
            },
            {
                path: 'grade/:gradeId/concept/:conceptId/chapter/:chapterId',
                component: LessonsInChaptersListComponent,
            },
            {
                path: 'list',
                canActivate: [CanShowLessonListGuard],
                children: [
                    {
                        path: '',
                        component: LessonsListComponent,
                    },
                    {
                        path: 'filters/:title',
                        component: LessonsListComponent
                    },
                    {
                        path: 'search',
                        component: LessonsListComponent
                    },
                    {
                        path: 'lessons',
                        component: OnlyLessonLessonsListComponent
                    },
                    {
                        path: 'models',
                        component: OnlyModelLessonsListComponent
                    },
                    {
                        path: 'algebra',
                        component: OnlyModelLessonsListComponent
                    },
                    {
                        path: 'geometry',
                        component: OnlyModelLessonsListComponent
                    },
                    {
                        path: 'community',
                        component: CommunityListComponent
                    },
                    {
                        path: 'recommendation',
                        component: RecommendationComponent
                    },
                    {
                        path: 'user-lessons',
                        component: UserLessonsComponent
                    }]
            },
            {
                path: ':lessonId',
                children: [
                    {
                        path: 'page',
                        component: LessonPageComponent
                    },
                    {
                        path: 'player',
                        component: LessonsComponent,
                        children: [
                            {
                                path: 'recap',
                                component: LessonsRecapComponent
                            },
                            {
                                path: 'reward',
                                component: RewardPageComponent
                            }, {
                                path: 'summary',
                                component: SummarySubLessonComponent
                            },
                            {
                                path: '',
                                loadChildren: () => import('@modules/activities/core/player-components/players.module').then(m => m.PlayersModule)
                            }
                        ]
                    },
                    {
                        path: 'edit',
                        canActivate: [CanLaunchOldEditorGuard],
                        children: [
                            {
                                path: '',
                                resolve: {
                                    lessonActivities: LessonActivitiesResolver
                                },
                                component: LessonActivitiesComponent
                            },
                            {
                                path: 'stepto',
                                children: [
                                    {
                                        path: '',
                                        component: EditLessonComponent,
                                        children: [
                                            {
                                                path: ':activityId',
                                                children: [
                                                    {
                                                        path: 'video',
                                                        component: VideoEditorComponent,
                                                    },
                                                    {
                                                        path: 'poll',
                                                        component: ShortAnswerMultiEditorComponent,
                                                    },
                                                    {
                                                        path: 'summary',
                                                        component: SummaryComponent,
                                                    },
                                                    {
                                                        path: 'resources',
                                                        component: CorpusDisplayEmbedComponent,
                                                    }
                                                ]
                                            },
                                        ]
                                    },
                                ]
                            },
                            {
                                path: 'activities',
                                component: ActivitiesListComponent
                            },
                            {
                                path: 'resources',
                                component: CorpusDisplayEmbedComponent,
                            },
                            {
                                path: '',
                                loadChildren: () => import('@modules/activities/core/editor-components/editors.module').then(m => m.EditorsModule)
                            },
                        ]
                    },
                    {
                        path: 'editor',
                        children: [
                            {
                                path: '',
                                loadChildren: () => import('@modules/activities/core/lessons/editor/editor.module').then(m => m.EditorModule)
                            }
                        ]
                    },
                    {
                        path: 'activities',
                        component: ListOfActivitiesComponent
                    },
                    {
                        path: 'activities/:subLessonIdentifier', // On le double pour pouvoir forcer un des onglets du composant
                        component: ListOfActivitiesComponent
                    },
                    {
                        path: '**',
                        pathMatch: 'full',
                        redirectTo: 'player'
                    }
                ]
            }
        ]
    },
    // {
    //     path: 'forms',
    //     canActivate: [IsUserLogged],
    //     children: [
    //         {
    //             path: 'list',
    //             component: FormsListComponent
    //         },
    //         {
    //             path: ':formId',
    //             children: [
    //                 {
    //                     path: 'player',
    //                     resolve: {
    //                         formList: ResolverForFormPlayer
    //                     },
    //                     component: FormPlayerComponent,
    //                 },
    //                 {
    //                     path: '**',
    //                     pathMatch: 'full',
    //                     redirectTo: 'player'
    //                 }
    //             ]
    //         }
    //     ]
    // },
    {
        path: 'sequences',
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'list',
                component: SequencesTabComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ActivitiesSharedModule,
        MatButtonModule,
        MatGridListModule,
        MatIconModule,
        MatProgressSpinnerModule,
        PlayersModule,
        DragulaModule,
        MatStepperModule,
        ActivitiesDirectiveModule,
        InfiniteScrollModule,
        FuseLessonsModule,
        MatDialogModule,
        MatCheckboxModule,
        CommonSharedModule,
        EditorsModule,
        // NgxFileDropModule,
        ThemesModule,
        SearchFiltersModule,
        ProgressBarGenericModule,
        NgChartsModule,
        NgxFileDropModule,
        ActivitiesPipesModule,
        MatProgressBarModule,
        CarouselModule,
       // ContentModule,
       UserReviewModule,
       MatBottomSheetModule
    ],
    declarations: [
        LessonsComponent,
        LessonsListComponent,
        // FormsListComponent,
        SequencesTabComponent,
        LessonsTabComponent,
        FormPlayerComponent,
        // EditFormDialogComponent,
        // FormActivitiesComponent,
        LessonsRecapComponent,
        EditLessonDialogComponent,
        LessonActivitiesComponent,
        LessonMetadataDialogComponent,
        LessonsFavoritesComponent,
        EditLessonComponent,
        OnlyLessonLessonsListComponent,
        OnlyModelLessonsListComponent,
        LessonsConsultedComponent,
        LessonsCreatedComponent,
        CommunityListComponent,
        DeleteLessonWarningComponent,
        EditLessonWarningComponent,
        RecommendationComponent,
        LauncherComponent,
        LauncherButtonComponent,
        SummarySubLessonComponent,
        ListOfActivitiesComponent,
        LessonPageComponent,
        UserLessonsComponent,
        PeriodListComponent,
        LessonsInChaptersListComponent,
        AddLessonButtonMorphableComponent,
        OldSchoolComponent,
        NeoComponent,
    ],
    exports: [
        RouterModule,
    ],
    providers: []
})
export class LessonsModule {
    private static isMenuSetLessons = false;
    private onLogout$ = new Subject<void>();


    private dynamicNavigation = inject(DynamicNavigationService);
    private authorization = inject(AuthorizationService);
    private authorizationService = inject(LessonAuthorizationService);
    private communicationCenter = inject(CommunicationCenterService);
    private activitiesService = inject(ActivitiesService);
    private config = inject(LessonsConfigurationService);
    private usagesService = inject(UsagesService);

    private autonomyUsage: UsageEntity;
    private collectiveUsage: UsageEntity;

    get onlyModels(): FuseNavigationItem {
        return {
            'id': 'onlyModels',
            'icon': 'transition',
            'title': 'onlyModels',
            'translate': 'generic.models',
            'type': 'item',
            'url': '/lessons/list/models',
            'displayIcon': this.activitiesService.settings.menu['models']
        };
    }

    get onlyCollective(): FuseNavigationItem {
        return {
            'id': 'onlyCollective',
            'icon': 'transition',
            'title': 'onlyCollective',
            'translate': 'generic.collective',
            'type': 'item',
            'url': '/lessons/list/search',
            'queryParams': {'usage': this.collectiveUsage?.id},
            'displayIcon': this.activitiesService.settings.menu['models']
        };
    }

    get onlyIndividuals(): FuseNavigationItem {
        return {
            'id': 'onlyIndividuals',
            'icon': 'transition',
            'title': 'onlyIndividuals',
            'translate': 'generic.individual',
            'type': 'item',
            'url': '/lessons/list/search',
            'queryParams': {'usage': this.autonomyUsage?.id},
            'displayIcon': this.activitiesService.settings.menu['models']
        };
    }

    get onlyLessons(): FuseNavigationItem {
        return {
            'id': 'onlyLessons',
            'title': 'onlyLessons',
            'translate': 'generic.my_lessons',
            'type': 'item',
            'url': `/lessons/list/lessons`
        };
    }

    get onlyCommunity(): FuseNavigationItem {
        return {
            'id': 'onlyCommunity',
            'title': 'onlyCommunity',
            'translate': 'generic.community',
            'type': 'item',
            'url': '/lessons/list/community'
        };
    }

    get recommendation(): FuseNavigationItem {
        return {
            'id': 'recommendation',
            'title': 'recommendation',
            'translate': 'generic.recommendation',
            'type': 'item',
            'icon': 'recommendation',
            'url': '/lessons/list/recommendation'
        };
    }

    get userLessons(): FuseNavigationItem {
        return {
            'id': 'userLessons',
            'title': 'userLessons',
            'translate': 'activities.title_user_lessons',
            'type': 'item',
            'url': '/lessons/list/user-lessons'
        };
    }


    get allLessonsList(): FuseNavigationItem[] {
        return [
            this.onlyLessons,
            this.onlyModels,
            this.onlyCollective,
            this.onlyIndividuals,
            this.onlyCommunity,
            this.recommendation,
            this.userLessons
        ];
    }

    constructor() {
        this.authorizationService.activeRulesOnStartup();

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('lessons')
            .next('lessonsConsultedComponent', LessonsConsultedComponent);

        this.communicationCenter
            .getRoom('lessons')
            .next('lessonsCreatedComponent', LessonsCreatedComponent);

        this.communicationCenter
            .getRoom('lessons')
            .next('lessonsFavoritesComponent', LessonsFavoritesComponent);

        this.communicationCenter
            .getRoom('recommendation')
            .next('recommendationListComponent', RecommendationComponent);

        this.communicationCenter
            .getRoom('lessons')
            .next('buttonListComponent', ButtonListDialogComponent);

        this.communicationCenter
            .getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Assignment',
                type: 'FEEDBACK_FORM',
                icon: 'activity',
                text: 'assignment.notification_feedback_assignment',
                textTransform: (text: string, data: Object) => {
                    return {
                        projectName: data['projectName'],
                        formName: data['formName'],
                    };
                },
                action: (data: Object) => {
                    return ['followed', 'list'];
                }
            });


        this.communicationCenter
            .getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Assignment',
                type: 'LEARNER_ANSWER_FORM_SENDED',
                icon: 'activity',
                text: 'assignment.notification_learner_answer_assignment',
                textTransform: (text: string, data: Object) => {
                    return {
                        projectName: data['projectName'],
                        formName: data['formName'],
                    };
                },
                action: (data: Object) => {
                    return ['followed', 'list'];
                }
            });
    }

    static forRoot(): ModuleWithProviders<LessonsModule> {

        return {
            ngModule: LessonsModule,
            providers: [
                LessonsService,
                LessonActivitiesResolver,
                // ResolverForFormPlayer,
                FlagService,
                {
                    provide: MatPaginatorIntl,
                    useClass: CustomMatPaginatorIntl
                }
            ]
        };
    }

    private postLogout(): void {
        LessonsModule.isMenuSetLessons = false;
        this.onLogout$.next();
        this.onLogout$.complete();
        this.clearDynamicMenu();
    }

    private clearDynamicMenu(): void {
        LessonsModule.isMenuSetLessons = false;
        this.dynamicNavigation.clearMenuItem('level0', 'Lessons');
        this.dynamicNavigation.clearMenuItem('level0', 'lessonsDeployable');

        this.allLessonsList.forEach((navigationItem) => {
            this.dynamicNavigation.clearMenuItem('level0', navigationItem.id);
        });
    }

    private getDeployableSubLessonItems(): FuseNavigationItem[] {
        return this.config.getDeployableChildrenLessonItems(this.allLessonsList);
    }

    private postAuthentication(): void {
        this.onLogout$ = new Subject<void>();

        if (!LessonsModule.isMenuSetLessons) {
            this.usagesService.getUsages()
                .pipe(
                    tap((usagesCollection) => {
                        this.autonomyUsage = usagesCollection.entities.find((usageEntity) =>
                            usageEntity.get('label') === this.usagesService.autonomyUsageLabel);
                        this.collectiveUsage = usagesCollection.entities.find((usageEntity) =>
                            usageEntity.get('label') === this.usagesService.collectiveUsageLabel);;
                    }),
                    mergeMap(() => this.authorization.currentUserCan<Observable<boolean>>(AsyncRules.AccessLessonList)),
                    takeUntil(this.onLogout$),
                    tap((isAuthorized) => {
                        if (isAuthorized === false) {
                            this.clearDynamicMenu();
                        } else {
                            this.dynamicNavigation.refreshModuleMenu('level0', {
                                'id': 'Lessons',
                                'title': 'Lessons',
                                'translate': 'activities.title_lessons',
                                'type': 'item',
                                'icon': 'lessons',
                                'url': '/lessons/list'
                            });

                            this.dynamicNavigation.refreshModuleMenu('level0', [
                                {
                                    'id': 'lessonsDeployable',
                                    'title': 'lessonsDeployable',
                                    'translate': 'activities.title_lessons',
                                    'type': 'collapse',
                                    'icon': 'lessons',
                                    'children': this.getDeployableSubLessonItems()
                                },
                                ...this.allLessonsList
                            ]);

                            this.usagesService.getUsageByLabel(this.usagesService.collectiveUsageLabel).subscribe();

                            LessonsModule.isMenuSetLessons = true;
                        }
                    })
                ).subscribe();
        }
    }
}
