export const cards = {
    cardDisplay: {
        showButtonThemeLesson: false,
        showButtonBookmarks: {
            themes: false,
            lesson: false,
            model: false,
            community: false,
            image: false,
            video: false,
            videoUrl: false,
            audio: false,
            document: false,
            url: false,
        },
        isTextButton: false,
    },
    cardDefaultHeader: '/assets/marqueblanche/images/backgrounds/header.png',
    cardFields: {
        default: {
            lesson: ['title', 'menu', 'dynamicLevelTypeImage', 'educationalLevel', 'description', 'assignation_type'],
            model: ['title', 'menu', 'dynamicLevelTypeImage', 'educationalLevel', 'description', 'assignation_type'],
            community: ['title', 'menu', 'dynamicLevelTypeImage', 'educationalLevel', 'description', 'assignation_type'],
            image: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            video: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            videoUrl: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            audio: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            document: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            url: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
        }
    },
    cardPlayOpenMenu: false,
    menuLinks: {
        lesson: {
            manager: [
                'editAndDeleteAssignments',
                'deleteWithAssignments',
                'duplicate'
            ],
            default: [
                'editAndDeleteAssignments',
                'deleteWithAssignments',
                'assign',
                'duplicate'
            ]
        },
        model: {
            manager: [
                'editAndDeleteAssignments',
                'deleteWithAssignments',
                'duplicate'
            ],
            default: [
                'assign',
                'duplicate'
            ]
        }
    },
    lessonPageMenuActions: {
        lesson: {
            manager: [
                'editAndDeleteAssignments',
                'deleteWithAssignments',
                'duplicate'
            ],
            default: [
                'editAndDeleteAssignments',
                'deleteWithAssignments',
                'assign',
                'duplicate'
            ]
        },
        model: {
            manager: [
                'editAndDeleteAssignments',
                'deleteWithAssignments',
                'duplicate'
            ],
            default: [
                'assign',
                'duplicate'
            ]
        }
    }
};
