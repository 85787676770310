import {inject, Injectable} from '@angular/core';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs';

export interface UserReview {
    nid: string;
    uid: string;
    value: ReviewChoices;
}

export enum ReviewChoices {
    dissatisfied = '-2',
    sad = '-1',
    satisfied = '1',
    verysatisfied = '2',
}
@Injectable({
    providedIn: 'root'
})
export class UserReviewService {
    private octopusConnect = inject(OctopusConnectService);
    constructor() { }

    saveUserReview(review: UserReview): Observable<DataEntity> {
        return this.octopusConnect.createEntity('user-review', review);
    }

    getUserReview(uid: number, nid: number): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('user-review', {'nid': nid, 'uid': uid});
    }
}
