import {TypologyLabel} from '@modules/activities/core/typologies/typology.label';

export type LessonActivityRoutesType = Record<TypologyLabel, string | undefined>;

export const LessonActivityRoutes: LessonActivityRoutesType = {
    'APP': 'appaire',
    'Audio': 'audio',
    awareness: 'awareness',
    'CRT': 'shortanswer',
    'DRL': 'draw-line',
    'EXT': 'external',
    'GCM': 'gcm', // grid choice multiple
    'IMG': 'image',
    'IMGI': 'interactiveimage',
    imgsoundzone: 'imgsoundzone',
    MEM: 'memory',
    missing: 'missing',
    missingaudio: 'missingaudio',
    'MULTI': 'medias',
    'ORD': 'ordon',
    'ORDMAT': 'orderingmatching',
    'TXTMAT': 'text-matching',
    'QCM': 'qcm',
    'POINTIMG': 'pointimg', // image-zoning
    'QCMU': 'qcu',
    'POINTWORD': 'qcu',
    'POINTMULTIWORD': 'qcm',
    recording: 'recording',
    'RB': 'fillinblanks',
    'Tool': 'tool',
    'VF': 'truefalse',
    'summary': 'summary',
    'video': 'video',
    CP: undefined,
    MULTIAC: undefined,
    bd: undefined,
    divider: undefined,
    mindmap: undefined,
    notepad: undefined,
    timeline: undefined,
};

export interface IFormatIdentifier {
    label: string;
    id?: string;
}

/**
 * Options used to load a lesson by url with some temporary settings (one-time settings no needed every times)
 */
export interface NavigateToLessonOptions {
    assignmentId?: string | number;
    assignatedUserId?: string | number;
    exitLessonUrl: string; // used to overwrite the default lesson exit route.
    // if page list of activities must be displayed
    isActivitiesListMustBeDisplayed?: boolean;
    subLessonId?: string | number; // Used to select sublesson to load
    startOnStepIndex?: number; // Used to overwrite the default activity to load by another on the lesson list by this index,
    navigateDirectlyToSummary?: boolean // navigate to summary after lessonLoaded
    preview?: boolean, // navigate to summary after lessonLoaded
    quizEnabled?: boolean, // navigate to quiz after lessonLoaded
}
