import {takeUntil} from 'rxjs/operators';
import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable, Subject} from 'rxjs';
import {AccountManagementProviderService} from '../../../account-management/core/account-management-provider.service';
import {ProfileService} from '../../../account-management/core/profile/profile.service';
import {AuthenticationService} from '../authentication.service';
import {DynamicNavigationService} from '../../../../navigation/dynamic-navigation.service';
import {defaultRoute} from '../../../../settings';
import {UserDataEntity} from "@modules/authentication";

@Component({
    selector: 'fuse-reset-password',
    templateUrl: './reset-password.component.html'
})
export class FuseResetPasswordComponent implements OnInit, OnDestroy {
    token: string;
    private unsubscribeInTakeUntil = new Subject<void>();

    private connector = inject(OctopusConnectService);
    private route = inject(ActivatedRoute);
    public router = inject(Router);
    private authenticationService = inject(AuthenticationService);

    ngOnInit(): void {
        this.route.params.subscribe((value: Object) => {
            this.token = value['token'];
            this.setToken(this.token);

        });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    setToken(token): void {
        let myDate = new Date();
        let timestampDate;
        myDate.setHours(myDate.getHours() + 24);
        timestampDate = Math.floor(new Date(myDate).getTime());

        localStorage.setItem('http_accessToken', JSON.stringify(token));
        localStorage.setItem('http_expires_in', JSON.stringify(timestampDate));
        localStorage.setItem('http_currentUser', JSON.stringify({}));

        const obs: Observable<DataEntity> = this.connector.authenticated('http');

        obs.pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((userData: UserDataEntity) => {
                this.authenticationService.onAuthenticated(userData);
                if (userData.get('sso') || true) {
                    this.navigateInRegardOfIfWeAreGarOrNot();
                } else {
                    if (!!this.authenticationService.specificRedirectionPath()) {
                        this.router.navigate([this.authenticationService.specificRedirectionPath()]);
                    } else {
                        this.router.navigate(['/profile/show-profile/edit']);
                    }
                }
            }, (e) => {
                console.log('error' + e);
                this.router.navigate(['/forgot-password', 'errorTokenNotValid']);
            });
    }

    /**
     * for gar rustine learner must go on activities directly
     * @private
     */
    private navigateInRegardOfIfWeAreGarOrNot(): void {
        if (this.authenticationService.isGAR()) {
            let route = defaultRoute;
            if (
                this.authenticationService.settings.overrideDefaultRouteByRole &&
                this.authenticationService.settings.overrideDefaultRouteByRole[this.authenticationService.accessLevel]
            ) {
                route = this.authenticationService.settings.overrideDefaultRouteByRole[this.authenticationService.accessLevel];
            }
            this.router.navigate([route]);
        } else {
            this.router.navigate(['/profile/show-profile']);
        }
    }
}
