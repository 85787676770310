import {inject, Injectable} from '@angular/core';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {ActivitiesConfigurationService} from '@modules/activities/core/services/activities-configuration.service';
import {TypologyLabel} from '@modules/activities/core/typologies/typology.label';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataCollection, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TypologiesService {
    public allTypes: { label: string, id: string|number }[];

    private activitiesConfigurationService = inject(ActivitiesConfigurationService)
    private communicationCenter = inject(CommunicationCenterService)
    private octopusConnect = inject(OctopusConnectService)

    constructor() {
        this.loadActivityTypes().subscribe();
    }

    public getTypologyId(typology: TypologyLabel): number {
        const typeSelected = this.allTypes.find(
            (type) => type.label.toLowerCase() === typology.toLowerCase()
        );
        return typeSelected ? +typeSelected.id : 0;
    }

    /**
     * get multiple typologies ids
     * @param typologyLabel
     */
    public getTypologies(typologyLabel?: TypologyLabel): number[] {
        if (typologyLabel) {
            return [this.getTypologyId(typologyLabel)];
        }
        return this.activitiesConfigurationService.activitiesWithDefinedTypology().map((typology) => this.getTypologyId(typology));
    }

    public loadActivityTypes(): Observable<DataCollection> {
        const obs = this.octopusConnect
            .loadCollection('variables/instance')
            .pipe(take(1));

        obs.subscribe((collection) => {
            this.communicationCenter
                .getRoom('activities')
                .getSubject('typologies')
                .next(collection.entities[0].get('activityTypes'));
            return (this.allTypes = collection.entities[0].get('activityTypes'));
        });
        return obs;
    }
}
