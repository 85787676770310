import {ChangeDetectorRef, Component, EventEmitter, HostBinding, inject, Input, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {ERROR_MESSAGES, MediaService} from 'shared/media/media.service';
import {FileData, FileMime} from 'shared/models';
import {brand} from "../../../../../settings";

@Component({
  selector: 'app-media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.scss'],
})
export class MediaUploadComponent<T extends FileMime> {


    @Input() public media?: FileData<T> = null;
    @Input() public acceptedTypes = '';
    @Input() public uploadMethod: (file: File) => Observable<FileData<T>> = null;
    @Input() public placeholderMediaType? = 'image';

    @HostBinding('class') get placeholderClass() {
        return this.media == null || this.media.filemime == "audio/mpeg" ? `${this.placeholderMediaType}_placeholder` : null ;
    }

    @Output() public mediaChange = new EventEmitter<FileData<T>>();

    private changeDetectorRef = inject(ChangeDetectorRef);
    private mediaService = inject(MediaService);

    public mediaIsLoading = false;
    public errors: string[] = [];
    public isDeleteActive = false;
    public readonly brand = brand;

    // LOGIC

    private handleNoFileSelected(): void {
        this.mediaIsLoading = false;
        this.media = null;

        this.errors.push('activity_edition.upload_no_file');
        console.error('No file selected');
    }

    private handleMultipleFilesSelected(): void {
        this.mediaIsLoading = false;
        this.media = null;

        this.errors.push('activity_edition.upload_multiple_files');
        console.error('Multiple files selected');
    }

    private handleFileUploadSuccess(uploadedFile: FileData<T>): void {
        this.mediaIsLoading = false;
        this.media = uploadedFile;
        this.mediaChange.emit(uploadedFile);
        this.changeDetectorRef.detectChanges();
    }

    private handleFileUploadError(error: Error): void {
        this.mediaIsLoading = false;
        this.media = null;
        this.mediaChange.emit(null);

        if (error.message === ERROR_MESSAGES.invalidFileType) {
            this.errors.push('activity_edition.upload_invalid_file_type');
        } else if (error.message === ERROR_MESSAGES.fileTooBig) {
            this.errors.push('activity_edition.upload_file_too_big');
        } else {
            this.errors.push('activity_edition.generic_upload_error');
        }

        console.error(error)

        this.changeDetectorRef.detectChanges();
    }

    // EVENTS

    public onFileChange($event: Event): void {
        this.mediaIsLoading = true;
        this.media = null;
        this.errors = [];

        const inputElement = $event.target as HTMLInputElement;

        if (inputElement.files.length === 0) {
            return this.handleNoFileSelected();
        }

        if (inputElement.files.length > 1) {
            return this.handleMultipleFilesSelected();
        }

        const file: File = inputElement.files[0];

        if (file) {
            this.uploadMethod(file).subscribe({
                next: (uploadedFile) => this.handleFileUploadSuccess(uploadedFile),
                error: error => this.handleFileUploadError(error),
            });
        }
    }

    public onDeleteClick($event: MouseEvent): void {
        $event.preventDefault();
        $event.stopPropagation();

        this.mediaService.deleteMedia(this.media.id).subscribe(() => {
            this.media = null;
            this.mediaChange.emit(null);
            this.isDeleteActive = false;

            this.changeDetectorRef.detectChanges();
        });
    }

    public onActivateDeleteClick($event: MouseEvent): void {
        $event.preventDefault();
        $event.stopPropagation();
        this.isDeleteActive = true;
    }

    public onCancelDelete($event: MouseEvent): void {
        $event.preventDefault();
        $event.stopPropagation();
        this.isDeleteActive = false;
    }

}
