import {Injectable} from '@angular/core';
import {ProjectsService} from '@modules/projects-management/core/projects.service';
import {CorpusServicesInstantiatorService} from '@modules/corpus';
import {GroupsManagementServicesInstantiatorService} from '@modules/groups-management';
import {ResearchSheetService} from '@modules/research-sheet';
import {SvgIconList} from './themes/mathia/svgIconList';
import {AssignmentServicesInstantiatorService} from '@modules/assignation';
import {ActivitiesServicesInstantiatorService} from '@modules/activities';
import {AuthenticationServicesInstantiatorService} from "@modules/authentication";
import {LicensingService} from '@modules/licensing/core/licensing.service';
import {GamificationService} from '@modules/gamification/core/gamification.service';
import {MessagingService} from '@modules/messaging';
import {ConfigurationService} from '@modules/configuration';
import {IdeasWallService} from '@modules/ideas-wall/core/ideas-wall.service';
import {NotepadServicesInstantiatorService} from '@modules/notepad';
import {MindmapServicesInstantiatorService} from '@modules/mindmap';
import {TimelineService} from '@modules/timeline';
import {NewsService} from 'fuse-core/news/news.service';
import {SnackbarNewsService} from 'fuse-core/news/snackbar/snackbar-news.service';
import {GraphHumanumService} from '@modules/graph-humanum';
import {GraphInclusiveService} from '@modules/graph-inclusive';
import {GraphBayardService} from '@modules/graph-bayard';
import {GraphMathiaService} from '@modules/graph-mathia';
import {GraphDysappService} from '@modules/graph-dysapp';
import {GraphUbolinoService} from '@modules/graph-ubolino';
import {GlobalAuthorizationService} from './global-authorization.service';
import {AchievementServicesInstantiatorService} from "@modules/achievement";
import {OnboardingService as GamificationOnboardingService} from '@modules/gamification/core/onboarding/onboarding.service';
import {AccountManagementServicesInstantiatorService} from "@modules/account-management";
import {BdService} from '@modules/bdtool';
import {BasicPageService} from 'fuse-core/components/basic-page/basic-page.service';
import {LrsService} from '@modules/lrs';
import {GamecodeServicesInstantiatorService} from "@modules/gamecode";
import {FormDialogService} from "fuse-core/components/form-dialog/form-dialog.service";


@Injectable()
export class ServiceInstantiator {

    constructor(
        private basicPageService: BasicPageService,
        private formDialogService: FormDialogService,
        private projectsService: ProjectsService,
        private researchSheetService: ResearchSheetService,
        private SvgIconList: SvgIconList,
        private licensingService: LicensingService,
        private gamificationService: GamificationService,
        private messaginService: MessagingService,
        private configurationService: ConfigurationService,
        private ideasWallService: IdeasWallService,
        private timelineService: TimelineService,
        private newsService: NewsService,
        private snackbarNewsService: SnackbarNewsService,
        private graphHumanumService: GraphHumanumService,
        private graphInclusiveService: GraphInclusiveService,
        private graphBayardService: GraphBayardService,
        private graphMathiaService: GraphMathiaService,
        private graphDysappService: GraphDysappService,
        private graphUbolinoService: GraphUbolinoService,
        private globalAuthorizationService: GlobalAuthorizationService,
        private gamificationOnboardingService: GamificationOnboardingService,
        private bdService: BdService,
        private lrsService: LrsService,
        private accountManagementServices: AccountManagementServicesInstantiatorService,
        private achievementServices: AchievementServicesInstantiatorService,
        private activitiesServices: ActivitiesServicesInstantiatorService,
        private assignmentServices: AssignmentServicesInstantiatorService,
        private authenticationServices: AuthenticationServicesInstantiatorService,
        private corpusServices: CorpusServicesInstantiatorService,
        private gamecodeServices: GamecodeServicesInstantiatorService,
        private groupsManagementServices: GroupsManagementServicesInstantiatorService,
        private mindmapsServices: MindmapServicesInstantiatorService,
        private notepadServices: NotepadServicesInstantiatorService,
    ) {
    }

}
