import {filter, map, take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject, Subscription} from 'rxjs';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {
    Group,
    GroupDataCollection,
    Institution,
    Learner
} from '@modules/groups-management/core/definitions';
import {CommunicationCenterService} from '@modules/communication-center';
import {GroupsManagementService} from '@modules/groups-management/core/services/groups-management.service';
import {ContextualService} from "@modules/groups-management/core/services/contextual.service";
import {Subject} from 'rxjs/index';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class GroupService {
    public groupsList: Group[] = [];
    public onGroupsChanged: BehaviorSubject<any> = new BehaviorSubject([]);
    public archiveMode = false;
    public schoolyear = 'all';
    public selectedClass: DataEntity;
    private levels: DataEntity[];
    private levelsLoaded = false;
    private educationalLevels: DataEntity[];
    private userData: DataEntity;
    private groupsCollection: { [key: number]: DataEntity } = {};
    private groupsSubscription: Subscription = null;
    private learnersList: Learner[] = [];
    private filteredInstitutionList: Array<Institution> = [];

    private dataUpdated = new ReplaySubject<void>();

    constructor(
        private groupsManagement: GroupsManagementService,
        private contextualService: ContextualService,
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                this.userData = data;
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('projects-management')
            .getSubject('setProjectGroups')
            .subscribe((data) => this.updateProjectInGroups(data));

        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('joinGroup')
            .pipe(
                tap((data: {id: string, callback: (string) => Observable<DataEntity>}) =>this.joinGroup(data.id)),
                tap((data: {id: string, callback: (string) => Observable<DataEntity>}) => data.callback(this.getClass(data.id)))
            )
            .subscribe();


        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('getGroups')
            .subscribe(({callbackSubject}: { callbackSubject: Subject<Observable<GroupDataCollection>> }) =>
                callbackSubject.next(this.loadGroups())
            );

        this.setupContextual();
    }

    public get groups(): object[] {
        return this.groupsList.slice();
    }

    public loadGroups(): Observable<GroupDataCollection> {
        return this.octopusConnect.loadCollection('groups') as Observable<GroupDataCollection>;
    }

    private loadLevels(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('levels').pipe(take(1));
    }

    private loadEducationalLevels(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('educational_level').pipe(take(1));
    }

    addGroup(group): Observable<DataEntity> {
        const obs = this.octopusConnect
            .createEntity('groups', {
                label: group.groupname.trim(),
                type: this.groupsManagement.settings.groupType.toString(),
                archived: false,
                projects: [],
                level: group.level || group.educationalLevel,
                color: group.color,
                schoolyear_term: group.schoolyear_term ? group.schoolyear_term : '',
                parents: this.isInstitutionAssociated(group)
            });
        obs.subscribe(() => this.contextualService.onConditionUpdate());

        return obs;
    }

    /**
     * Given a list of groups, add a project ID to those groups and remove project ID from groups not listed.
     * @param data Object containing groups ID (groups) and project ID (project)
     */
    updateProjectInGroups(data): void {
        const projectId = data.project.toString();

        // Remove projectId from groups not listed in data.groups
        this.groupsList
            .filter((group: Group) => group.projects.indexOf(projectId) > -1)
            .forEach((group: Group) => {
                if (data.groups.indexOf(group.id) === -1) {
                    group.projects.splice(group.projects.indexOf(projectId), 1);
                    this.saveGroup(group);
                }
            });

        // Add projectId to groups listed in data.groups
        this.groupsList
            .filter((group: Group) => data.groups.indexOf(group.id) > -1)
            .forEach((group: Group) => {
                if (group.projects.indexOf(projectId) === -1) {
                    group.projects.push(projectId);
                    this.saveGroup(group);
                }
            });
    }

    saveGroup(group): Observable<DataEntity> {
        const groupEntity = this.groupsCollection[group.id];

        if (groupEntity) {
            groupEntity.set('label', group.groupname.trim());
            groupEntity.set('archived', group.archived);
            groupEntity.set('metacognitionActive', group.metacognitionActive);
            groupEntity.set('projects', group.projects);
            groupEntity.set('color', group.color);
            groupEntity.set('schoolyear_term', group.schoolyear_term ? group.schoolyear_term : '');
            if (group.parent) {
                groupEntity.set('parents', group.parent.id);
            }
            groupEntity.set('level', group.level || group.educationalLevel);
            return groupEntity.save();
        }
    }

    deleteGroup(group): Observable<boolean> {
        const groupEntity = this.groupsCollection[group.id];

        if (groupEntity) {
            const observable = groupEntity.remove();
            observable.subscribe(() => this.contextualService.onConditionUpdate());
            return observable;
        }
    }

    getParents(): { id: string, name: string; field: string; hideCode: boolean; code: string }[] {
        return this.filteredInstitutionList;
    }

    getGroups(): string[] {
        return this.groupsList
            .map((group: Group) => group.groupname);
    }
    
    public getGroupFromName(groupname: string): Group {
        return this.groupsList.find(group => group.groupname === groupname);
    }

    getLevels(): Observable<DataEntity[]> {
        if (this.levelsLoaded) {
            return of(this.levels);
        } else {
            return this.loadLevels().pipe(
                map((collection: DataCollection) => collection.entities),
                tap((levels: DataEntity[]) => {
                    this.levels = levels;
                    this.levelsLoaded = true;
                })
            );
        }
    }
    
    public getEducationalLevels(): DataEntity[] {
        return this.educationalLevels;
    }

    public filterArchived(archived: boolean): any {
        this.archiveMode = archived;
        if (this.schoolyear !== 'all' && this.schoolyear !== '0') {
            // cumul filter schoolYear and archived
            this.onGroupsChanged.next(this.groupsList.filter((group) => group.archived === archived)
                .filter((group) => group.schoolyear_term && group.schoolyear_term['id'] === this.schoolyear));
        } else {
            this.onGroupsChanged.next(this.groupsList.filter((group) => group.archived === archived));
        }
    }

    /**
     * filter on school year take care of archived flag
     * @param schoolYearFromFilter
     */
    public filterSchoolYear(schoolYearFromFilter?: string): any {
        this.schoolyear = schoolYearFromFilter && schoolYearFromFilter !== '' ? schoolYearFromFilter : this.groupsManagement.getCurrentSchoolyearTermId();
        if (this.schoolyear === 'all') {
            this.onGroupsChanged.next(this.groupsList.filter((group) => group.archived === this.archiveMode));
        } else {
            this.onGroupsChanged.next(this.groupsList
                .filter((group) => group.schoolyear_term && group.schoolyear_term['id'] === this.schoolyear)
                .filter((group) => group.archived === this.archiveMode));
        }
    }

    public resetFilter(): any {
        this.onGroupsChanged.next(this.groupsList);
    }

    public joinGroup(group): any {

        if (typeof group === 'object') {
            if (!this.classExist(group.id) && +group.get('type') === 2) {
                const currentAllIds = [];
                currentAllIds.push(...this.userData.get('groups'));

                for (const entity in this.groupsCollection) {
                    if (currentAllIds.indexOf(entity) !== -1) {
                        currentAllIds.splice(currentAllIds.indexOf(entity), 1);
                    }
                }

                this.groupsList = [];
                this.groupsList.push({
                    id: +group.id,
                    groupname: group.get('label'),
                    color: group.get('color'),
                    learnersIds: [],
                    learners: 0,
                    level: (group.get('level') ? group.get('level') : ''),
                    code: (group.get('code') ? group.get('code') : group.id),
                    projects: (group.get('projects') ? group.get('projects').slice() : []),
                    archived: group.get('archived'),
                    metacognitionActive: group.get('metacognitionActive'),
                    parent: (group.get('parents') ? group.get('parents')[0] : ''),
                    schoolyear_term: (group.get('schoolyear_term') ? group.get('schoolyear_term') : ''),
                    admins: group.get('admins'),
                });
                this.onGroupsChanged.next(this.groupsList);
                currentAllIds.push(group.id);
                this.userData.set('groups', currentAllIds);
                return this.userData.save();
            }
        }  else if (typeof group === 'string') {
            if (!this.classExist(group)){
                const currentAllIds = [group];

                this.userData.set('groups', currentAllIds);
                return this.userData.save();
            }
        }

    }

    public getClass(id): Observable<DataEntity> {
        const obs = this.octopusConnect.loadEntity('groups', +id);
        obs.subscribe((entity: DataEntity) => {
            this.selectedClass = entity;
        });

        return obs;
    }

    public classExist(id): boolean {
        return id && this.userData.get('groups').indexOf(id.toString()) !== -1 && !!this.groupsList.find((item) => item.id.toString() === id.toString());
    }

    public removeClassFromList(entity): void {
        if (entity || this.selectedClass && !this.classExist(this.selectedClass.id)) {
            const id = entity && entity.id ? entity.id : this.selectedClass.id;
            const index = this.groupsList.findIndex((item) => item.id.toString() === id.toString());
            if (index !== -1) {
                this.groupsList.splice(index, 1);
                this.onGroupsChanged.next(this.groupsList);
            }
        }
    }

    /**
     * return the scholar year
     */
    public schoolYears(startDate: number): string {
        const date = new Date(startDate * 1000);
        // year begin 1er août and finish 31 juillet
        const month = date.getMonth();
        const year = date.getFullYear();
        let yearBegin: number = null;
        // 1 aout => 31 december
        if (month > 6 && month < 12) {
            yearBegin = year;
        }
        // december to aout exclude
        if (month < 7) {
            yearBegin = year - 1;
        }
        return yearBegin + '-' + (yearBegin + 1);
    }

    private postLogout(): void {
        if (this.groupsSubscription) {
            this.groupsSubscription.unsubscribe();
            this.groupsSubscription = null;
        }
    }

    private postAuthentication(): void {
        if (!this.groupsSubscription) {

            const learnerList$ = this.communicationCenter
                .getRoom('groups-management')
                .getSubject('learnerList');

            const subjectInstitutionListChanged = this.communicationCenter
                .getRoom('groups-management')
                .getSubject<Institution[]>('institutionListFiltered');

            const obsLoad = this.loadGroups();

            this.groupsSubscription = combineLatest([obsLoad, subjectInstitutionListChanged]).subscribe((data) => {
                this.groupsList = [];
                this.formatEntityInstitution(data[0].entities);
                this.filteredInstitutionList = data[1];
                this.filterArchived(this.archiveMode);
            });


            obsLoad.pipe(take(1)).subscribe((data: DataCollection) => {

                subjectInstitutionListChanged.subscribe((institutionListFiltered: Array<{ id: string, name: string; field: string; hideCode: boolean; code: string }>) => {
                    this.filteredInstitutionList = institutionListFiltered;
                });

                learnerList$.pipe(filter(learnerList => !!learnerList)).subscribe((learnersList: Learner[]) => {
                    this.learnersList = learnersList;
                    this.groupsList.forEach((group: Group) => {
                        const learners = this.learnersList
                            .filter((learner: Learner) => learner.groups.indexOf(group.groupname) > -1)
                            .map((learner: Learner) => learner.id);

                        group.learners = learners.length;

                        group.learnersIds = this.learnersList
                            .filter((learner: Learner) => learner.groups.indexOf(group.groupname) > -1)
                            .map((learner: Learner) => +learner.id);
                    });

                    this.dataUpdated.next();
                });
            });

            subjectInstitutionListChanged.subscribe((institutionListFiltered: Array<any>) => {
                this.filteredInstitutionList = institutionListFiltered;
            });

            this.loadEducationalLevels().pipe(
                map((collection: DataCollection) => collection.entities),
                tap((educationalLevels: DataEntity[]) => this.educationalLevels = educationalLevels)
            ).subscribe();
        }
        if (this.groupsManagement.settings.filterGroupListingsByCurrentYearByDefault) {
            this.filterSchoolYear();
        }
    }

    public formatEntityInstitution(entities): void {
        for (const entity of entities) {
            if ((parseInt(entity.get('type') ? entity.get('type').toString() : '', 10)) === this.groupsManagement.settings.groupType) {
                const group: Group = {
                    id: parseInt(entity.id.toString(), 10),
                    groupname: entity.get('label'),
                    color: entity.get('color'),
                    learnersIds: [],
                    learners: 0,
                    level: (entity.get('level') ? entity.get('level') : ''),
                    code: (entity.get('code') ? entity.get('code') : entity.id).toString(),
                    projects: (entity.get('projects') ? entity.get('projects').slice() : []),
                    archived: entity.get('archived'),
                    metacognitionActive: entity.get('metacognitionActive'),
                    parent: (entity.get('parents') ? entity.get('parents')[0] : ''),
                    schoolyear_term: (entity.get('schoolyear_term') ? entity.get('schoolyear_term') : null),
                    admins: entity.get('admins'),
                };

                this.groupsList.push(group);
                this.groupsCollection[entity.id] = entity;
            }
        }

        this.communicationCenter
            .getRoom('groups-management')
            .next('groupsList', this.groupsList);
    }

    private isInstitutionAssociated(val: Group): string[] {
        if (val && val.parent) {
            return [val.parent.id];
        }
        if (this.groupsManagement.isGroupMustBeAssociatedWithUsersInstitution()
            && this.filteredInstitutionList && this.filteredInstitutionList.length) {
            return [this.filteredInstitutionList[0].id];
        }
        else {
            return null;
        }
    }

    private setupContextual(): void {
        this.contextualService.conditionGroupNoGroup$
            .subscribe((callback) => {
                this.dataUpdated.subscribe(() => callback(this.groupsList.length === 0));
            });

        this.contextualService.conditionGroupNoLearnerInGroup$
            .subscribe((callback) => {
                this.dataUpdated.subscribe(() => callback(this.groupsList.every((group) => group.learners === 0)));
            });
    }
}
