
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {take} from 'rxjs/operators';
import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {Subject} from 'rxjs';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {VideoMarkersComponent} from '@modules/activities/core/editor-components/video-editor/video-markers/video-markers.component';
import {UploadResourceComponent} from '@modules/activities/core/shared-components/upload-resource/upload-resource.component';
import * as _ from 'lodash-es';
import {VideoInfo} from 'js-video-url-parser/lib/urlParser';
import urlParser from 'js-video-url-parser';



@Component({
  selector: 'app-video-editor',
  templateUrl: './video-editor.component.html'
})

export class VideoEditorComponent implements OnInit, OnDestroy {

    public options: any;
    public instruction: string;
    public wording: string;
    public markerTypes: DataEntity[] = [];
    public showSpinner: boolean;
    public videoReady: boolean;

    private unsubscribeInTakeUntil = new Subject<void>();
    private videoUrl: any;
    private activity: DataEntity;
    private params: any;
    private markers: any[] = [];
    private dialogRef: MatDialogRef<VideoMarkersComponent>;
    private resourceDialogRef: MatDialogRef<UploadResourceComponent>;
    private urlForBack: string;
    private selectedMarkerType: any;
    private selectedMarker: any;
    private videoConfig: any;
    private markersLimit: number;

    private lessonsConfigurationService = inject(LessonsConfigurationService);
    private activityService = inject(ActivitiesService)
    private dialog = inject(MatDialog)
    private lessonsService = inject(LessonsService)
    private sanitizer = inject(DomSanitizer)
    private communicationCenter = inject(CommunicationCenterService)
    private router = inject(Router)
    private route = inject(ActivatedRoute)


    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.params = params;
            this.initVideo();
        });
    }

    initVideo(): void {
        if (this.showMarkers) {
            this.lessonsService.loadMarkerTypes()
                .subscribe((entities: DataEntity[]) => {
                    this.markerTypes = entities;
                });
        }

        if (!this.lessonsService.getCurrentActivity() || this.lessonsService.getCurrentActivity() && +this.params.activityId !== +this.lessonsService.getCurrentActivity().id) {
            this.showSpinner = true;
            this.activityService.loadActivitiesFromId(this.params.activityId.toString()).pipe(
                take(1))
                .subscribe((entity) => {
                    this.lessonsService.setCurrentActivity(entity);
                    this.initialize();
                });
        } else {
            this.initialize();
        }
    }

    ngOnDestroy() {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private initialize(): void {
        this.activity = this.lessonsService.getCurrentActivity();

        if (this.activity && typeof this.activity.get('reference') === 'object') {

            if (this.activity.get('reference').activity_content){

                const activityResourceRef = this.activity.get('reference').activity_content[0].granule[0].reference;

                if (this.activity.get('reference').activity_content[0].granule[0].format.label === 'video') {
                    this.videoUrl = activityResourceRef;
                } else {
                    const parsed: VideoInfo | undefined = urlParser.parse(activityResourceRef.url);
                    if (parsed || this.activityService.settings.urlVideoException.some((urlException) => activityResourceRef.url.includes(urlException))) { // check if video url
                        this.videoUrl = this.sanitizer
                            .bypassSecurityTrustResourceUrl(this.activityService.getVideoLinkInfo(activityResourceRef.url));
                    }
                }
                if (this.videoUrl) {
                    this.markers = typeof this.activity.get('reference') === 'object' ? this.activity.get('reference').activity_content[0].marker : [];
                    this.markersLimit = this.lessonStepSettings && this.lessonStepSettings.markersLimit ? this.lessonStepSettings.markersLimit : 6;
                    this.setVideo();
                }
            }
        }
        this.showSpinner = false;
    }

    public chooseResource(selectCorpus: {userCorpus: boolean, upload: boolean}): void {
        this.showSpinner = true;
        this.communicationCenter.getRoom('corpus').next('selectionMode', this.router.routerState.snapshot.url);
        const activities = [this.activity];
        this.setUrl = this.router.routerState.snapshot.url;
        if (this.isLessonWithStep) {
            this.communicationCenter
                .getRoom('activities')
                .next('selectionLessonEditorWithStepConfig', {
                    mode: true,
                    userCorpus: selectCorpus.userCorpus,
                    activities: activities,
                    callback: () => this.backToActivity()
                });
            if (selectCorpus.upload) {
                this.resourceDialogRef = this.dialog.open(UploadResourceComponent, {
                    data: null
                });

                this.resourceDialogRef
                    .afterClosed()
                    .subscribe(url => {
                        if (url) {
                            const parsed: VideoInfo | undefined = urlParser.parse(url); // check if video url
                            if (parsed || this.activityService.settings.urlVideoException.some((urlException) => url.includes(urlException))) {
                                this.videoUrl = this.sanitizer
                                    .bypassSecurityTrustResourceUrl(this.activityService.getVideoLinkInfo(url));
                                if (this.videoUrl) {
                                    this.markers = typeof this.activity.get('reference') === 'object' ? this.activity.get('reference').activity_content[0].marker : [];
                                    this.markersLimit = this.lessonStepSettings && this.lessonStepSettings.markersLimit ? this.lessonStepSettings.markersLimit : 6;
                                    this.setVideo();
                                }

                            }
                        }
                        this.showSpinner = false;
                    });

            } else {
                this.router.navigate(['lessons', this.activity.id, 'edit', 'resources'], {queryParams: this.params});
            }
        }
    }

    private backToActivity(): void {
        this.communicationCenter
            .getRoom('activities')
            .next('selectionLessonEditorWithStepConfig', {
                mode: false,
                userCorpus: null
            });
        this.router.navigateByUrl(this.urlActivity);
    }

    private setVideo(): void {
        this.videoConfig =
            {
                canEditMarker: true,
                openMarkerAutomatic: this.lessonStepSettings && this.lessonStepSettings.openMarkerAutomatic,
                markers: this.markers,
                overlayClicked: (marker, isNewMarker) => this.openSelectedMarker(marker, isNewMarker),
                removeMarker: (marker) => this.removeMarker(marker)
            };

        if (this.videoUrl && this.videoUrl.changingThisBreaksApplicationSecurity) {
            this.videoConfig['config'] = {
                src: this.videoUrl.changingThisBreaksApplicationSecurity,
            };
        } else {
            this.videoConfig['config'] = {
                src: this.videoUrl.uri,
                type: this.videoUrl.filemime,
            };
        }

        if (this.activityService.settings.urlVideoException.some((urlException) => this.videoUrl.url.includes(urlException))) {
            this.videoConfig['config'] = {
                src: this.videoUrl.url,
                type: 'video/mp4',
            };
        }

        this.videoReady = true;
    }

    public addMarker(marker = null): void {
        if (+this.markers.length < +this.markersLimit) {
            this.selectedMarkerType = marker ? marker : {label: 'activities.personalize'};
            this.communicationCenter
                .getRoom('video')
                .next('action', 'new_marker');
        } else {
            this.communicationCenter
                .getRoom('video')
                .next('action', 'marker_limit_reached');
        }
    }

    public openSelectedMarker(selectedMarker, isNewMarker): void {
        this.selectedMarker = selectedMarker;
        if (!isNewMarker) {
            this.selectedMarkerType = selectedMarker.marker_type ? selectedMarker.marker_type : {label: 'activities.personalize'};
        }
        this.dialogRef = this.dialog.open(VideoMarkersComponent, {
            data: {
                lesson: this.lessonsService.currentLesson.get('metadatas').title,
                markerType: this.selectedMarker.marker_type ? this.markerTypes.find((markerType) => +this.selectedMarker.marker_type.id === +markerType.id) : this.selectedMarkerType,
                marker: this.selectedMarker,
                isEditor: true,
                videoConfig: _.cloneDeep(this.videoConfig),
                callback: (data) => this.saveMarker(data)
            }
        });

        this.dialogRef.afterClosed().subscribe((result) => {

            if (result) {
                this.reInitActivity();
            }

        });
    }

    private saveMarker(data): any {
        const marker = {
            title: data['title'],
            time: data['location'],
            description: [data['title'], data['location'], data['question'], data['clue'], data['tracks'], data['url']],
        };
        if (this.selectedMarker && this.selectedMarker.id) {
            marker['marker_type'] = this.selectedMarker.marker_type && this.selectedMarker.marker_type.id ? this.selectedMarker.marker_type.id : null;
            return this.lessonsService.saveMarker(this.selectedMarker, marker, null);
        } else {
            marker['marker_type'] = this.selectedMarkerType ? this.selectedMarkerType.id : null;
            return this.lessonsService.saveMarker(null, marker, this.markers.map((mark) => mark.id));
        }
    }

    private removeMarker(marker = null): any {
        this.lessonsService.removeMarker(marker ? marker : this.selectedMarker, this.markers.map((mark) => mark.id))
            .subscribe(() => {
                this.reInitActivity();
            });
    }

    private reInitActivity(): void {
        this.activityService.loadActivitiesFromId(this.params.activityId.toString()).pipe(
            take(1))
            .subscribe((entity) => {
                this.lessonsService.setCurrentActivity(entity);
                this.activity = entity;
                this.markers = entity.get('reference').activity_content[0].marker;
                this.communicationCenter
                    .getRoom('video')
                    .next('markers', this.markers);
            });
    }

    public exit(): void {
        this.router.navigate(['lessons', 'list']);
    }

    get showMarkers(): boolean {
        return this.lessonsConfigurationService.getLessonStep() &&
            this.lessonStepSettings &&
            !!this.lessonStepSettings.markers;

    }

    private get lessonStepSettings() {
        if (this.lessonsConfigurationService.getLessonStep()) {
            return this.lessonsConfigurationService.getLessonStep().typeSteps[this.params.stepIndex];
        }

        return null;
    }

    private get isLessonWithStep(): boolean {
        return this.lessonsService && !!this.lessonsConfigurationService.getLessonStep();
    }

    private set setUrl(url) {
        this.urlForBack = url;
    }

    private get urlActivity(): string {
        return this.urlForBack;
    }

}
