import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {takeUntil} from 'rxjs/operators';
import {Component, ElementRef, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {PaginatedCollection} from 'octopus-connect';
import {CollectionOptionsInterface} from 'octopus-connect';
import {DatacardService} from 'shared/datacard.service';
import {Subject} from 'rxjs';
import {CardsService} from 'fuse-core/components/card/cards.service';
import {LessonsCardService} from '@modules/activities/core/lessons/services/lessons-card.service';

@Component({
    selector: 'app-lessons-favorites',
    templateUrl: './lessons-favorites.component.html'
})

export class LessonsFavoritesComponent implements OnInit, OnDestroy {
    public dataCards: any[] = [];
    countEntities = 0;
    pageIndex = 0;
    pageRange = 6;
    pageRangeOptions = [6];
    public paginatorCollection: PaginatedCollection;
    @ViewChild('scrollContainer', {static: true}) scrollContainer: ElementRef;
    private optionsInterface: CollectionOptionsInterface;
    private type = 'all' as const;
    private unsubscribeInTakeUntil = new Subject<void>();

    private lessonsService = inject(LessonsService)
    private lessonsConfigurationService = inject(LessonsConfigurationService)
    private datacardService = inject(DatacardService)
    private cardService = inject(CardsService)
    private lessonsCardService = inject(LessonsCardService)

    ngOnInit(): void {
        this.optionsInterface = {
            filter: {
                bookmarks: true, // filter favorites
            },
            page: 1,
            range: 6
        };

        this.cardService.settingBookmark.subscribe(() => {
            this.loadLessons();
        });

        this.loadLessons();
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    setPaginator(): void {
        this.countEntities = this.lessonsService.favoritesLessonsPaginated.paginator.count;
        this.pageIndex = this.lessonsService.favoritesLessonsPaginated.paginator.page - 1;
        this.pageRange = this.lessonsService.favoritesLessonsPaginated.paginator.range;
    }

    onPaginateChange(event): void {
        this.scrollToTop();
        this.lessonsService.favoritesLessonsPaginated.paginator.page = event.pageIndex + 1;
    }

    /**
     * scroll to top when changing page
     */
    public scrollToTop(): void {
        this.scrollContainer.nativeElement.scrollTop = 0;
    }



    /**
     * Loads lessons that have been bookmarked
     */
    private loadLessons(): void {
        const callbacks = this.lessonsCardService.getCallbacks();

        this.lessonsService.loadPaginatedLessons(this.type, this.lessonsService.getAllowedRoleIdsForModelsCreation(), '', this.optionsInterface).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(resources => {
                if (!resources) {
                    return;
                }
                this.dataCards = this.datacardService.processResources(resources, callbacks, this.type);
                this.setPaginator();
            });
    }


    public getCardLayout() {
        return this.lessonsConfigurationService.getCardLayout();
    }
}
