export const corpus = {
    accessMatrix: {
        global: {
            view: ['learner', 'trainer', 'manager', 'administrator'],
            add: ['trainer', 'manager', 'administrator'],
            edit: ['trainer', 'manager', 'administrator'],
            delete: ['trainer', 'manager', 'administrator'],
            print: ['learner', 'trainer', 'manager', 'administrator'],
            selectAll: ['learner', 'trainer', 'manager', 'administrator'],
            download: ['trainer', 'manager', 'administrator'],
        },
        userCorpus: {
            view: ['trainer', 'manager', 'administrator'],
            add: ['trainer', 'manager', 'administrator'],
            edit: ['trainer', 'manager', 'administrator'],
            delete: ['trainer', 'manager', 'administrator'],
            print: ['learner', 'trainer', 'manager', 'administrator'],
            selectAll: ['trainer', 'manager', 'administrator'],
            download: ['trainer', 'manager', 'administrator'],
        },
    },
    addToLesson: true,
    allowedExtensions: 'jpg, jpeg, png, gif, mp4, mpeg, mp3, pdf',
    allowedAttachedFilesExtensions: 'pdf',
    allowErrorReporting: false,
    assetFields: {
        default: [
            'name',
            'educationalLevel',
            'concepts',
            'subTheme',
            'source',
            'description',
        ],
    },
    resourceCreationStepsMax: 2,
    assetRequiredFields: ['name'],
    assetTextEditorFields: ['description'],
    detailsFields: [
        'learning-time',
        'concepts',
        'chapters',
        'levels',
        'difficulty',
        'skills',
        'created',
        'scolomDate',
        'source-author',
        'source',
        'format',
        'description',
        'indexation',
        'size',
        'files',
        'shared',
    ],
    authorRolesGeneralCorpus: ['3', '4'], // 3 => administrator, 4 => gestionnaire, 5 => formateur, 6 => apprenant
    cardLayout: 'card-split-media-info',
    columns: {
        default: [
            'icon',
            'title',
            'level',
            'concept',
            'favorite',
            'buttonsAction',
        ],
    },
    fileMaxSize: '1',
    rolesCanShowBannerInfo: ['administrator', 'manager', 'director', 'trainer', 'learner'],
    filesWhiteList: [
        'image/jpg',
        'image/jpeg',
        'image/png',
        'image/gif',
        'video/mp4',
        'video/mpeg',
        'audio/mp3',
        'audio/mpeg',
        'application/pdf',
    ],
    filters: {
        default: [
            'educationnalLevel',
            'concepts',
            'difficulty',
            'thematics',
            'type',
            'title',
            'keywords',
            'bookmarks',
            'launchSearch'
        ],
    },
    globalCorpus: '11057',
    mediaTypes: ['audio', 'document', 'image', 'text', 'url', 'video'], // 'audio', 'document', 'image', 'media', 'text',  'url', 'video', 'videoUrl'
    showButtonOptions: true,
    terms: {
        globalCorpus: {
            title: 'generic.title_global_corpus',
            columns: {
                type: 'generic.type_global_corpus',
                author: 'generic.author_global_corpus',
                group: 'generic.group_global_corpus',
            },
        },
        research: {
            title: 'projects-management.research_space',
            columns: {
                type: 'generic.type',
                author: 'generic.author',
                group: 'generic.group',
            },
        },
        lesson: {
            title: 'generic.title_lesson_corpus',
            columns: {
                type: 'generic.type',
                author: 'generic.author',
                group: 'generic.group',
            },
        },
    },
    textEditor: {
        enabled: true,
        config: { toolbar: ['bold', 'italic'] },
    },
    userCorpus: true, // define if there is a corpus to list current user assets
    fileAllowedToDownloadInGlobalCorpus: ['document', 'image'], // even if global corpus is in mode readonly if the file is allowed it can be donwloaded
    displayHeader: false,
    shareableResourceToCommunity: false, // display community corpus
    addButtonInSidebar: false,
    displayVisibility: false,
};
