import {takeUntil} from 'rxjs/operators';
import {AfterViewChecked, Component, inject} from '@angular/core';
import * as _ from 'lodash-es';
import {Observable, of} from 'rxjs';
import {DragulaService, Group} from 'ng2-dragula';
import {BaseActivityComponent} from '@modules/activities/core/player-components/base-activity.component';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';
import {PairingActivityGranule} from '@modules/activities/core/models/activities/typologies/pairing-activity.granule';

@Component({
    selector: 'app-appaire',
    templateUrl: './appaire.component.html',
    providers: [DragulaService]
})

export class AppaireComponent extends BaseActivityComponent<PairingActivityGranule> implements AfterViewChecked {
    public answers = [];
    public dragContainers: any[] = [];
    public dragEnabled = true;
    public displayFeedback = false;
    public isVertical = false;
    /**
     * Seems to be the droppable data (the draggable data will be associated to the droppable by the user). The order has to be mixed.
     */
    public quesAnswer = [];
    /**
     * Seems to be the draggable data (the draggable data will be associated to the droppable by the user). The order is mixed.
     */
    public quesImages = [];
    private answersDragGroup: Group; // dragula Group
    private disableDragWhenReady = false;

    private dragulaService = inject(DragulaService)

    ngAfterViewChecked(): void {
        if (this.disableDragWhenReady) {
            this.disableDragWhenReady = false;
            this.disableAllDragBlock();
            this.enableNotCloneDragBlock();
        }
    }

    /**
     * enable all block except if they have status cloned = dropped to appaire
     */
    private enableNotCloneDragBlock(): void {
        // Ce code n'est pas compatible avec la nouvelle version de dragula et je n'ai pas trouvé de solution pour le moment
        // const leftBag = this.answersDragGroup.drake.containers[0].children;
        // this.quesImages.forEach((item, index) => {
            // if (!item.cloned) {
            //     leftBag[index].classList.remove('cloned');
            // }
        // });
    }

    protected setContentData(activityAttributes): void {
        if (activityAttributes.reference.config) {
            this.isTwoColumns = activityAttributes.reference.config.doubleColumn !== 0;
            this.isVertical = (activityAttributes.reference.config.direction === 'vertical');
        }
        this.referenceActivityGranule = activityAttributes.reference;
        this.quesImages = this.referenceActivityGranule.activity_content.answers_app;
        this.instruction = this.referenceActivityGranule.instruction;
        this.wording = this.referenceActivityGranule.wording;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;
        this.shuffle();
        this.initDragula();
        if (!this.isActivityEmbedded) {
            this.loadUserSave();
        }
    }

    private getDragBlockClassId(className: string): string {
        return className.substr(className.indexOf('drag-block-'), className.indexOf('drag-block-') + 1);
    }

    private initDragula(): void {
        this.resetDragContainers();
        if (this.answersDragGroup) {
            // Ce code n'est pas compatible avec la nouvelle version de dragula et je n'ai pas trouvé de solution pour le moment
            // this.dragulaService.find(this.answersDragGroup.name).options.direction = this.isVertical ? 'horizontal' : 'vertical';
            this.dragulaSubscribe();
            return;
        }
        this.answersDragGroup = this.dragulaService.createGroup('ANSWERS', {
            // Ce code n'est pas compatible avec la nouvelle version de dragula et je n'ai pas trouvé de solution pour le moment
            // direction: this.isVertical ? 'horizontal' : 'vertical',
            // copy: (el, source) => {
            //     return el.className.includes('block-left') && !source.className.includes('answers-bag');
            // },
            copyItem: (item) => {
                const copy = _.cloneDeep(item);
                copy.draggable = true;
                return copy;
            },
            // removeOnSpill: true,
            // moves: (el, source, handle, sibling) => {
            //     return el.className.includes('block-left') && this.dragEnabled;
            // },
            // accepts: (el, source, handle, sibling) => {
            //     const classId = this.getDragBlockClassId(el.className);
            //     const firstSibling = source.children[0];
            //
            //     let possiblyAlreadyAwnsered = (firstSibling.classList.contains('block-left') && !firstSibling.classList.contains(classId));
            //     if (!possiblyAlreadyAwnsered && source.childElementCount > 2) {
            //         possiblyAlreadyAwnsered = true;
            //     }

                // return !source.className.includes('left') && (!possiblyAlreadyAwnsered);
            // },
            // invalid: (el, handle) => {
            //     return el.className && typeof el.className === 'string' && el.className.includes('cloned');
            // }
        });
        this.dragulaSubscribe();
    }

    public dragulaSubscribe(): void {
        this.dragulaService.dropModel('ANSWERS').pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(({name, el, target, source, sibling, sourceModel, targetModel, item}) => {
            if (item.draggable) {
                item.classId = this.getDragBlockClassId(el.className);
                this.onAnswerDrop(item, this.getDragBlockClassId(target.className));
            }
        });
        this.dragulaService.cloned('ANSWERS').pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(({original, cloneType}) => {
            if (cloneType === 'copy') {
                original.className += ' cloned';
            }
        });
        this.dragulaService.removeModel('ANSWERS').pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(({el, source, item, sourceModel}) => {
            // Ce code n'est pas compatible avec la nouvelle version de dragula et je n'ai pas trouvé de solution pour le moment
            // const container = this.answersDragGroup.options.containers[0].children;
            // for (let i = 0; i < container.length; i++) {
            //     if (container[i].className.includes(item.classId)) {
            //         container[i].classList.remove('cloned');
            //         break;
            //     }
            // }
            this.onAnswerRemove(item);
        });
        this.dragulaService.cancel('ANSWERS').pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(({el, container, source}) => {
            if (container === null) {
                for (let i = 0; i < source.children.length; i++) {
                    const classId = this.getDragBlockClassId(el.className);
                    if (source.children[i].className.includes(classId) && source.children[i].className.includes('left')) {
                        source.children[i].classList.remove('cloned');
                        break;
                    }
                }
            }
        });
    }

    private shuffle(): void {
        this.quesAnswer = _.cloneDeep(this.quesImages);
        this.quesAnswer = this.derange(this.quesAnswer);
        this.quesImages = this.derange(this.quesAnswer);
    }

    private derange(quesAnswer): Array<any> {
        const quesAnswerCopy = quesAnswer.slice();
        if (quesAnswerCopy.length < 2) {
            return quesAnswerCopy;
        }

        let result = [];
        let index, i, quesAnswerLength;
        let lastMoved = false;

        for (let i = 0, quesAnswerLength = quesAnswerCopy.length - 1; i < quesAnswerLength; i++) {

            if (quesAnswerCopy.length === 2 && !lastMoved) {
                result = result.concat(quesAnswerCopy.reverse().splice(0, 2));
                break;
            }

            do {
                index = Math.random() * quesAnswerCopy.length | 0;

            } while (quesAnswer.indexOf(quesAnswerCopy[index]) === result.length);

            result.push(quesAnswerCopy.splice(index, 1)[0]);
            lastMoved = lastMoved || index === quesAnswerCopy.length;
        }

        if (quesAnswerCopy.length) {
            result.push(quesAnswerCopy[0]);
        }
        return result;
    }

    private disableAllDragBlock(): void {
        // Ce code n'est pas compatible avec la nouvelle version de dragula et je n'ai pas trouvé de solution pour le moment
        // const leftBag = this.answersDragGroup.drake.containers[0].children;
        // for (let i = 0; i < leftBag.length; i++) {
        //     leftBag[i].classList.add('cloned');
        // }
    }

    protected setAnswer(): void {
        if ((this.userSave?.get('userActivity')?.entitySave?.answers) && !this.displayForSummary) {
            // TODO UTILISER VARIABLE answersSelected
            this.restoreAnswers(this.userSave?.get('userActivity')?.entitySave?.answers);
            if(!this.lessonsService.isTrainerSeeCorrection()) {
                this.activitiesService.userAnswer.next(this.userSave.get('userActivity').entitySave.answers);
                this.checkAnswer();
                this.testAnswer = true;
            }
        }
    }

    private restoreAnswers(answers: any): void {
        // restore original answer order
        this.quesImages = this.referenceActivityGranule.activity_content.answers_app;
        this.quesAnswer = _.cloneDeep(this.quesImages);

        this.resetDragContainers();
        for (let i = 0; i < answers.length; i++) {
            const itemDropped = _.cloneDeep(this.quesAnswer.find(qAnswer => qAnswer.id === answers[i]));
            if (itemDropped) {
                this.quesImages.find(qImage => qImage.id === itemDropped.id).cloned = true;
                itemDropped.draggable = true;
                itemDropped.dropped = null;
                this.quesAnswer[i].dropped = itemDropped;
                this.dragContainers[i].items = [itemDropped, this.quesAnswer[i]];
            }
        }
        this.dragContainers = this.derange(this.dragContainers);
        this.quesImages = this.derange(this.quesImages);
        this.quesAnswer = this.derange(this.quesAnswer);
        this.disableDragWhenReady = true;
    }

    private resetDragContainers(): void {
        this.dragContainers = [];
        this.quesAnswer.forEach((item, index) => {
            this.quesImages[index].cloned = false;
            this.quesAnswer[index].dropped = null;
            this.dragContainers.push({items: [this.quesAnswer[index]], index: index});
        });
    }

    private onAnswerDrop(item, targetClassId: string): void {
        const droppedOnId = parseInt(targetClassId.substr(item.classId.length - 1));
        if (this.quesAnswer[droppedOnId]) {
            this.quesImages[droppedOnId].cloned = true;
            item.ansno = +droppedOnId;
            item.dropped = null;
            this.quesAnswer[droppedOnId].dropped = _.cloneDeep(item);
            this.quesAnswer[droppedOnId].dropped.errorid = 0;
            if (this.quesAnswer.every(qAnswer => qAnswer.cloned)) {
                this.onOptionChange(true);
                this.activitiesService.doesUserResponsed.next(true);
            } else {
                this.onOptionChange(false);
            }
        }
    }

    private onAnswerRemove(item): void {
        if (this.quesAnswer[item.ansno]) {
            this.quesImages[item.ansno].cloned = false;
            this.quesAnswer[item.ansno].dropped = null;
            this.activitiesService.doesUserResponsed.next(false);
        }
    }

    protected getGrade(): any {
        let correctCnt;
        let oldSelectedCorrect = 0;
        let selectedCorrect = 0;

        let oldGrade = 0;
        let grade;

        correctCnt = this.quesAnswer.length;

        for (const item of this.quesAnswer) {
            if (item.dropped) {
                if (item.id === item.dropped.id && item.dropped.id) {
                    selectedCorrect += 1;
                }
            }
        }

        if (this.userSave) {
            const oldAnswers = this.userSave.get('userActivity').entitySave.answers;
            /**
             * {this.questionObject.activity_content.answers_app} are the original value.
             * The order of the original value is the correct order
             */
            this.referenceActivityGranule.activity_content.answers_app
                .map((item) => item.id)
                .forEach((val, index) => {
                    if (oldAnswers[index] !== null && oldAnswers[index] === val) {
                        oldSelectedCorrect += 1;
                    }
                });

            if (oldSelectedCorrect > 0) {
                oldGrade = oldSelectedCorrect / correctCnt;
            }
        }

        grade = selectedCorrect / correctCnt;

        return {
            newGrade: grade,
            oldGrade: oldGrade
        };
    }

    protected checkAnswer(): void {
        let correctAnsCnt = 0;
        this.answerStatus = answerStatusEnum.wrong;
        this.quesAnswer.forEach((item) => {
            if (item.dropped) {
                item.dropped.errorid = answerStatusEnum.wrong;
                if (item.id === item.dropped.id && item.dropped.id) {
                    item.dropped.errorid = answerStatusEnum.correct;
                    correctAnsCnt++;
                } else if (item.id !== item.dropped.id && item.dropped.id) {
                    item.dropped.errorid = answerStatusEnum.missing;
                }
            } else {
                this.answerStatus = answerStatusEnum.missing;
            }
        });

        if (this.quesAnswer.length === correctAnsCnt) {
            this.answerStatus = answerStatusEnum.correct;
        }
        this.dragEnabled = false;
        this.activitiesService.doesUserResponsed.next(true);
        /* state message showing */
        if (this.lessonsService.isLessonTest() || this.lessonsService.isLessonTraining()) {
            this.testAnswer = true;
            this.displayFeedback = this.activitiesService.settings.displayFeedback
                && this.answerStatus === answerStatusEnum.wrong
                && this.lessonsService.isLessonTraining()
                && !this.lessonsService.isAtLeastTrainer();
        }

        this.activitiesService.isUserAnswerStatus
            .next({status: this.answerStatus, index: this.activityStepIndex});

        if (this.testAnswer) {
            this.activitiesService.checkAnswers.next({lessonCorrected: true});
        }
    }

    /**
     * generate ID of answers to be saved
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        const answersId = _.cloneDeep(this.quesAnswer)
            .sort((a, b) => (+a.id) - (+b.id))
            .map((item) => item.dropped && +item.dropped.id);
        return of(answersId);
    }

    protected seeAnswerSolution(): void {
        this.displaySolution = true;
        this.dragContainers = [];
        this.quesAnswer.forEach((item, index) => {
            item.cloned = true;
            const clonedItem = _.cloneDeep(item);
            clonedItem.dropped = null;
            clonedItem.errorid = 1;
            clonedItem.draggable = true;
            clonedItem.cloned = true;
            this.quesAnswer[index].dropped = clonedItem;
            this.dragContainers.push({items: [clonedItem, this.quesAnswer[index]], index: index});
        });
        this.disableAllDragBlock();

        this.dragEnabled = false;
        this.displayFeedback = false;
    }

    protected reset(resetAllSubscribe = false, type = null): Observable<boolean> {
        this.resetDragContainers();
        if (this.answersDragGroup) {
            // Ce code n'est pas compatible avec la nouvelle version de dragula et je n'ai pas trouvé de solution pour le moment
            // const leftBag = this.answersDragGroup.drake.containers[0].children;
            // for (let i = 0; i < leftBag.length; i++) {
            //     leftBag[i].classList.remove('cloned');
            // }
        }
        this.dragEnabled = this.lessonsService.isLessonTest() || !this.lessonsService.isAtLeastTrainerAndAssignmentExist;
        this.shuffle();
        return super.reset(resetAllSubscribe, type);
    }

    protected reviewAnswer(): void {
        this.displayFeedback = this.activitiesService.settings.displayFeedback
            && this.answerStatus === answerStatusEnum.wrong
            && this.lessonsService.isLessonTraining()
            && !this.lessonsService.isAtLeastTrainer();

        this.reset(false, 'reviewAnswer');
        this.testAnswer = true;
        // restore answer only if add one and if almost one response is not null
        if (this.userSave && this.userSave.get('userActivity') && this.userSave.get('userActivity').entitySave.answers.filter(res => res !== null).length > 0) {
            const answers = this.userSave.get('userActivity').entitySave.answers;
            this.restoreAnswers(answers);
            this.checkAnswer();
        } else {
            // we don't have choose solution so we go back like if user click on reset button
            this.shuffle();
            this.reset(false, 'reset');
        }
    }

    public get isPreview(): boolean {
        return this.lessonsService.isLessonTest() || this.lessonsService.isLessonTraining();
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    protected validate(): void {
        throw new Error('Method not implemented.');
    }
}
