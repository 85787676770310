
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {takeUntil} from 'rxjs/operators';
import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {DatacardService} from 'shared/datacard.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {Subject} from 'rxjs';
import {LessonsCardService} from '@modules/activities/core/lessons/services/lessons-card.service';

@Component({
    selector: 'app-lessons-consulted',
    templateUrl: './lessons-consulted.component.html'
})
export class LessonsConsultedComponent implements OnInit, OnDestroy {
    public dataCards: any[] = [];

    private type = 'currentUser';
    private unsubscribeInTakeUntil = new Subject<void>();

    private lessonsService = inject(LessonsService)
    public lessonsConfigurationService = inject(LessonsConfigurationService)
    private datacardService = inject(DatacardService)
    private lessonsCardService = inject(LessonsCardService)

    ngOnInit(): void {
        const callbacks = this.lessonsCardService.getCallbacks();
        this.lessonsService.loadConsultedLessons().pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(resources => {
                this.dataCards = this.datacardService.processResources(resources.slice(0, 6), callbacks, this.type);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }
}
