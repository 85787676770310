export enum SyncRules {
    AccessFormsModels = 'activities.access-forms-models',
}

export enum AsyncRules {
    AccessLessonList = 'activities.access-lessons-list',
    DuplicateLesson = 'activities.duplicate-lesson',
    EditLesson = 'activities.edit-lesson',
    PreviewLesson = 'activities.preview-lesson',
}
