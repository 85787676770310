export const mainMenu = {
    displayMenu: {
        manager: {
            'level0':
                [
                    {
                        'name': 'dashboard',
                        'translate': {
                            'parent': {
                                default: 'navigation.dashboard_trainer',
                            }
                        }
                    },
                    {
                        'name': 'parameter',
                        'translate': {
                            'parent': {
                                learner: 'generic.my_classes_learner',
                                default: 'navigation.groups-management'
                            },
                            'childs': {
                                'param-child-groups': {
                                    learner: 'generic.my_class',
                                    default: 'groups-management.classes'
                                },
                                'param-child-workgroups': {
                                    learner: 'generic.my_workgroups',
                                    default: 'groups-management.workgroups'
                                }
                            }
                        }
                    },
                    'onlyModels',
                    'recommendation',
                    {
                        'name': 'followed-tab',
                        'translate': {
                            'parent': {
                                learner: 'assignment.title_my_activity',
                                default: 'assignment.title'
                            }
                        }
                    },
                    // followed with tab include followed list and diary log use only in mathia for moment
                    'graph-mathia',
                    'feedback',
                    'mentions-legales',
                    'cgurgpd',
                    'politique-confidentialite',
                    'accessibility'
                ],
        },
        learner: {
            'level0':
                [
                    {
                        'name': 'dashboard',
                        'translate': {
                            'parent': {
                                default: 'navigation.dashboard',
                            }
                        }
                    },
                    {
                        'name': 'parameter',
                        'translate': {
                            'parent': {
                                learner: 'generic.my_classes_learner',
                                default: 'navigation.groups-management'
                            },
                            'childs': {
                                'param-child-groups': {
                                    learner: 'generic.my_class',
                                    default: 'groups-management.classes'
                                },
                                'param-child-workgroups': {
                                    learner: 'generic.my_workgroups',
                                    default: 'groups-management.workgroups'
                                }
                            }
                        }
                    },
                    {
                        'name': 'followed-tab',
                        'translate': {
                            'parent': {
                                learner: 'assignment.title_my_activity',
                                default: 'assignment.title'
                            }
                        }
                    },
                    'graph-mathia-single-own-progress',
                    // followed with tab include followed list and diary log use only in mathia for moment
                    'feedback',
                    'mentions-legales',
                    'cgurgpd',
                    'politique-confidentialite',
                    'accessibility',
                    'access-adventure'
                ],
        },
        trainer: {
            'level0':
                [
                    'dashboard',
                    {
                        'name': 'parameter',
                        'translate': {
                            'parent': {
                                learner: 'generic.my_classes_learner',
                                default: 'navigation.groups-management'
                            },
                            'childs': {
                                'param-child-groups': {
                                    learner: 'generic.my_class',
                                    default: 'groups-management.classes'
                                },
                                'param-child-workgroups': {
                                    learner: 'generic.my_workgroups',
                                    default: 'groups-management.workgroups'
                                }
                            }
                        }
                    },
                    'lessonsDeployable',
                    'recommendation',
                    {
                        'name': 'followed-tab',
                        'translate': {
                            'parent': {
                                learner: 'assignment.title_my_activity',
                                default: 'assignment.title'
                            }
                        }
                    },
                    // followed with tab include followed list and diary log use only in mathia for moment
                    'graph-mathia',
                    'mentions-legales',
                    'cgurgpd',
                    'politique-confidentialite',
                    'feedback',
                    'accessibility'
                ],
        },
        default: {
            'level0':
                [
                    {
                        'name': 'dashboard',
                        'translate': {
                            'parent': {
                                default: 'navigation.dashboard_trainer',
                            }
                        }
                    },
                    {
                        'name': 'parameter',
                        'translate': {
                            'parent': {
                                learner: 'generic.my_classes_learner',
                                default: 'navigation.groups-management'
                            },
                            'childs': {
                                'param-child-groups': {
                                    learner: 'generic.my_class',
                                    default: 'groups-management.classes'
                                },
                                'param-child-workgroups': {
                                    learner: 'generic.my_workgroups',
                                    default: 'groups-management.workgroups'
                                }
                            }
                        }
                    },
                    'lessonsDeployable',
                    {
                        'name': 'followed-tab',
                        'translate': {
                            'parent': {
                                learner: 'assignment.title_my_activity',
                                default: 'assignment.title'
                            }
                        }
                    },
                    // followed with tab include followed list and diary log use only in mathia for moment
                    'graph-mathia',
                    'mentions-legales',
                    'cgurgpd',
                    'politique-confidentialite',
                    'accessibility',
                    'feedback'
                ],
        }
    }
};
