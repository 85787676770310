import {Component, EventEmitter, HostListener, Input, Output, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-activity-edition-radio-button',
    templateUrl: './activity-edition-radio-button.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ActivityEditionRadioButtonComponent {
    @Input() icon: string;
    @Input() ariaLabel: string;
    @Input() idElement;
    @Input() name = 'mon-radio-button';
    @Input() controlName?: FormControl;
}
