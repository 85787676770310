// Définition de SwitchType en dur pour Alpha
import {ArrayStructure} from 'octopus-model/src/structures/array-structure.class';
import {DateStructure} from 'octopus-model/src/structures/date-structure.class';
import {NumberStructure} from 'octopus-model/src/structures/number-structure.class';
import {ObjectStructure} from 'octopus-model/src/structures/object-structure.class';
import {StringStructure} from 'octopus-model/src/structures/string-structure.class';
import {Structure} from 'octopus-model/src/structures/structure.class';

export type SwitchSettingsType<T> = {
    [K in keyof T]:
            T[K] extends StringStructure ? string :
            T[K] extends DateStructure ? Date :
            T[K] extends NumberStructure ? number :
            T[K] extends ArrayStructure ? Array<any>:
            T[K] extends ObjectStructure ? any :
            T[K] extends Structure ? boolean : // Attention, les autres types de structures plus haut sont des Structure donc il faut les traiter avant sinon elles seront traitées comme des boolean
            T[K]};

