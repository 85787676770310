export class SvgIcon {
    [key: string]: string
}

export const SVGICONTHEME: SvgIcon = {
    'allTypes': 'toustypes',
    'archive': 'archiver',
    'arrow_left_circle_outline': 'arrow_left_circle_outline',
    'arrow_right_circle_outline': 'arrow_right_circle_outline',
    'assignment_ind': 'assignment_ind',
    'brand': 'brand',
    'brand_square': 'brand',
    'class_add': 'add',
    'close': 'cross',
    'contact': 'contact',
    'cross': 'cross',
    'editor-play': 'play_outline',
    'trash': 'trash',
    'cross_outline': 'cross_outline',
    'delete': 'trash',
    'document': 'document',
    'drag_indicator': 'arrow_down_circle_outline',
    'edit': 'edit',
    'file_download': 'file_download',
    'duplicate': 'duplicate',
    'feedback': 'contact',
    'file': 'corpus',
    'fill-in-blanks': 'remplir_blancs',
    'icon-arrow-back': 'arrow_back',
    'institution_add': 'add',
    'lessons': 'transition',
    'link': 'url',
    'lock': 'lock',
    'pdf': 'ressources',
    'play': 'play_outline',
    'round-videoprojeter': 'round_videoprojeter',
    'round-videoprojeteroff': 'round_videoprojeteroff',
    'save': 'save',
    'shield': 'confi',
    'step-done': 'step_done',
    'step-inprogress': 'step_inprogress',
    'step-pasfait': 'step_pasfait',
    'student_add': 'check_circle_outline',
    'trainer_add': 'add',
    'groups-form-add': 'check',
    'transition': 'transition',
    'unarchive': 'desarchiver',
    'unassign': 'unassign',
    'unlock': 'unlock',
    'visibility': 'visibility',
    'visibility_legalnotice': 'visibility',
    'visibility_cgurgpd': 'cgu',
    'visibility_privacy': 'privacy',
    'workgroup_add': 'add',
    'xxx': 'dislike',
    'accessibility': 'accessibility',
    'code_sand_box': 'code_sand_box'
};
