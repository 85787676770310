import {filter, map, mergeMap, take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CollectionOptionsInterface, DataCollection, DataEntity, OctopusConnectService, OrderDirection, PaginatedCollection} from 'octopus-connect';
import {combineLatest, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {TranslateService} from '@ngx-translate/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {localizedDate, localizedTime} from 'app/shared/utils/datetime';
import {AuthenticationService} from '@modules/authentication';
import * as _ from 'lodash-es';
import {AssignationConfigurationService} from '@modules/assignation/core/services/assignation-configuration.service';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect/typed-data-entity.interface';
import {DataService} from 'fuse-core/services/data.service';
import {IAssignmentByStepResult} from '@modules/assignation/core/components/assignment-by-steps/assignment-by-step.service';
import {FilterData} from "octopus-connect/lib/models/types";

@Injectable()
export class AssignationService {
    public assignations: any = [];
    /**
     * @deprecated must use AssignationConfigurationService
     */
    public settings: { [key: string]: any };
    public assignmentsPaginated: PaginatedCollection;
    public learnerMethods: (string | number)[] = [];
    public schoolYearsList: DataEntity[];
    public states: TypedDataEntityInterface<{}>[] = [];
    private userData: UserDataEntity;
    private rawLearnersList: any[] = [];
    private rawGroupsList: any[] = [];
    private rawWorkgroupsList: any[] = [];
    private selectedProject: any;
    private methodDialogInfos: { title: string, body: string, ok: string } = {title: '', body: '', ok: ''};
    private settingsLicensing: { [key: string]: any } = {'restrict': []};
    private types: any[] = [];
    private currentAssignment: DataEntity;
    private savePending: { [key: string]: Observable<DataEntity> } = {};
    private saveQueue: { [key: string]: DataEntity } = {};
    public learners = [];
    public classes = [];
    public workgroups = [];

    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private translate: TranslateService,
        private authService: AuthenticationService,
        private config: AssignationConfigurationService,
        private sharedEntitiesService: DataService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: UserDataEntity) => {
                this.userData = data;
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('current')
            .subscribe((assignment: DataEntity) => {
                this.currentAssignment = assignment;
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('launchAssignment')
            .subscribe((data) => {
                this.launchAssignment(data).subscribe();
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('createAssignmentWithSubject')
            .pipe(
                tap(({assignment, onComplete}: { assignment: { [key: string]: any }, onComplete: Subject<Observable<DataEntity>> }) =>
                    onComplete.next(this.createEntityAssignment(assignment)))
            )
            .subscribe();

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('createAssignment')
            .subscribe((data: {
                assignment: { [key: string]: any },
                callback: (arg?) => any,
                dontUseSpecificConfiguration?: boolean
            }) => {
                this.createAssignmentWithCallBack(data);
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('createAssignmentV2')
            .subscribe((data: {
                assignment: IAssignmentByStepResult,
                callback: (arg?) => any,
            }) => {
                this.createAssignmentWithCallBackV2(data);
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('comment')
            .subscribe(data => {
                if (this.currentAssignment && data.comment) {
                    this.currentAssignment.set('changed', Math.floor(new Date().getTime() / 1000));
                    this.currentAssignment.set('comment', data.comment);

                    if (this.savePending[this.currentAssignment.id]) {
                        this.queueSave(this.currentAssignment);
                    } else {
                        this.saveAssignment(this.currentAssignment);
                    }
                }
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('changeState')
            .subscribe(data => {
                if (this.currentAssignment && data.state) {
                    this.currentAssignment.set('changed', Math.floor(new Date().getTime() / 1000));
                    this.currentAssignment.set('state_term', this.getState(data.state));

                    if (this.savePending[this.currentAssignment.id]) {
                        this.queueSave(this.currentAssignment);
                    } else {
                        this.saveAssignment(this.currentAssignment);
                    }
                }
            });

        this.communicationCenter
            .getRoom('assignation')
            .getSubject('saveGrade')
            .subscribe((data) => {
                if (this.currentAssignment && data.grade !== undefined) {
                    this.currentAssignment.set('changed', Math.floor(new Date().getTime() / 1000));
                    this.currentAssignment.set('grade', data.grade);

                    if (this.savePending[this.currentAssignment.id]) {
                        this.queueSave(this.currentAssignment);
                    } else {
                        this.saveAssignment(this.currentAssignment);
                    }
                }
            });

        this.communicationCenter
            .getRoom('assignation')
            .getSubject('saveProgress')
            .subscribe((data) => {
                if (this.currentAssignment && data.progress !== undefined) {
                    this.currentAssignment.set('changed', Math.floor(new Date().getTime() / 1000));
                    this.currentAssignment.set('progress', data.progress);

                    if (this.savePending[this.currentAssignment.id]) {
                        this.queueSave(this.currentAssignment);
                    } else {
                        this.saveAssignment(this.currentAssignment);
                    }
                }
            });

        this.communicationCenter
            .getRoom('assignation')
            .getSubject('event')
            .subscribe((data) => {
                if (data.id && data.event) {
                    this.octopusConnect.loadEntity('assignations', data.id + '/' + data.event);
                }
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('launch')
            .subscribe((id: string | number) => {
                this.loadAndGetAssignmentById(id).pipe(
                    take(1),
                    mergeMap(assignment => this.launchAssignment(assignment))
                ).subscribe();
            });

        this.settings = this.config.settings;

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('loadAvailableGroups')
            .subscribe((load: boolean) => {
                if (load) {
                    this.loadPaginatedAssignmentGroupList({}, true);
                } else {
                    this.communicationCenter
                        .getRoom('assignation')
                        .next('assignmentsGroup', {assignmentsGroup: []});
                }
            });

        this.communicationCenter.getRoom('licenses')
            .getSubject('settings')
            .subscribe((data) => {
                this.settingsLicensing = data;
            });

        this.communicationCenter.getRoom('licenses')
            .getSubject('methods')
            .subscribe((entities: DataEntity[]) => {
                this.learnerMethods = entities.filter((entity) => !!entity['id'])
                    .map((entity) => entity.id);
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('getAssignmentsById$')
            .pipe(
                tap(({assignmentId, onComplete}: { assignmentId: string | number, onComplete: Subject<Observable<DataEntity>> }) =>
                    onComplete.next(this.loadAndGetAssignmentById(assignmentId)))
            ).subscribe();

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('getAssignmentsByLesson$')
            .pipe(
                tap(({lessonId, onComplete}: { lessonId: string | number, onComplete: Subject<Observable<DataCollection>> }) =>
                    onComplete.next(this.loadAndGetAssignmentByLessonId(lessonId)))
            ).subscribe();

        this.communicationCenter
            .getRoom('assignment')
            .next('isEditable$', this.isEditable.bind(this));

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('deleteLessonAssignments$')
            .pipe(
                tap(({lessonId, onComplete}: { lessonId: string | number, onComplete: Subject<Observable<boolean[]>> }) =>
                    onComplete.next(this.deleteLessonAssignments(lessonId))
                )
            ).subscribe();

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('getAssignments$')
            .pipe(
                tap(({filterOptions, onComplete}: { filterOptions: CollectionOptionsInterface, onComplete: ReplaySubject<PaginatedCollection> }) =>
                    onComplete.next(this.octopusConnect.paginatedLoadCollection('assignation_search', filterOptions))
                )
            ).subscribe();
        this.communicationCenter
            .getRoom('assignment')
            .getSubject('getEducationalLevels$')
            .pipe(
                tap((onComplete: ReplaySubject<Observable<DataEntity[]>>) =>
                    onComplete.next(this.getEducationalLevels())
                )
            ).subscribe();
        this.communicationCenter
            .getRoom('assignment')
            .getSubject('getChapters$')
            .pipe(
                tap((onComplete: ReplaySubject<Observable<DataEntity[]>>) =>
                    onComplete.next(this.getChapters())
                )
            ).subscribe();
        this.communicationCenter
            .getRoom('assignment')
            .getSubject('getConcepts$')
            .pipe(
                tap((onComplete: ReplaySubject<Observable<DataEntity[]>>) =>
                    onComplete.next(this.getConcepts())
                )
            ).subscribe();

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('refresh')
            .subscribe((filterOptions) => {
                if (filterOptions) {
                    this.communicationCenter
                        .getRoom('assignment')
                        .next('loadPaginatedAssignmentsCallback', (filterOptions) => {
                            return this.octopusConnect.paginatedLoadCollection('assignation_search', filterOptions);
                        });
                }
            });

        this.communicationCenter
            .getRoom('assignment')
            .next('loadPaginatedAssignmentsCallback', (filterOptions) => {
                const obs = this.octopusConnect.loadCollection('assignation_search', filterOptions);
                obs.pipe(
                    tap((collection: DataCollection) => {
                        this.communicationCenter
                            .getRoom('assignment')
                            .next('assignmentsAssignedToUser', collection.entities);
                    })
                ).subscribe();
                return obs;
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('saveSubLessonProgress')
            .pipe(
                tap((assignmentProgressData: { id: string, score: number, scoreByActivities: { [key: string]: number } }) => {
                    this.saveProgressInSubLesson(assignmentProgressData);
                })
            )
            .subscribe();

        combineLatest([this.sharedEntitiesService.loadLearners(), this.sharedEntitiesService.loadClasses(), this.sharedEntitiesService.loadWorkgroups()])
            .pipe(
                tap(([learners, classes, workgroups]) => {
                    this.learners = learners;
                    this.classes = classes;
                    this.workgroups = workgroups;
                })
            )
            .subscribe();

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('loadPaginatedAssignments')
            .pipe(
                tap(({filter, onComplete}: { filter: CollectionOptionsInterface, onComplete: Subject<Observable<DataEntity[]>> }) => {
                    onComplete.next(this.loadPaginatedAssignments({filter}));
                })
            )
            .subscribe();

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('loadPaginatedAssignmentsGroup')
            .pipe(
                tap(({filter, onComplete}: { filter: CollectionOptionsInterface, onComplete: Subject<Observable<DataEntity[]>> }) => {
                    onComplete.next(this.loadPaginatedAssignmentGroup({filter}));
                })
            )
            .subscribe();

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('loadAssignmentsGroup')
            .pipe(
                tap((onComplete: Subject<Observable<DataEntity[]>>) => {
                    onComplete.next(this.loadAssignmentsGroup());
                })
            )
            .subscribe();
    }

    private _dataSource: MatTableDataSource<any> = new MatTableDataSource();

    get dataSource(): MatTableDataSource<any> {
        return this._dataSource;
    }

    // Attention, au chargement, on a pas encore les learner, donc le tableau this.rawLearnersList est null.
    public get learnersList(): any[] {
        let filteredLearners = this.rawLearnersList;

        if (this.settings.useSchoolYearFilter) {
            filteredLearners = filteredLearners.filter((learner) => !!learner.schoolyear_term && learner.schoolyear_term.name === this.currentSchoolYearBegin.toString());
        }
        return filteredLearners;
    }

    public get groupsList(): any[] {
        let filteredGroups = this.rawGroupsList.filter((group) => !group.archived);
        if (this.selectedProject) {
            filteredGroups = filteredGroups.filter((group) => group.projects.find((project) => +project === +this.selectedProject.id));
        }
        if (this.settings.useSchoolYearFilter) {
            filteredGroups = filteredGroups.filter((group) => group.schoolyear_term && group.schoolyear_term['name'] === this.currentSchoolYearBegin.toString());
        }
        return filteredGroups;
    }

    public get workgroupsList(): any[] {
        let filteredGroups = this.rawWorkgroupsList.filter((group) => !group.archived);
        if (this.selectedProject) {
            filteredGroups = filteredGroups.filter((group) => group.projects.find((project) => +project === +this.selectedProject.id));
        }
        if (this.settings.useSchoolYearFilter) {
            filteredGroups = filteredGroups.filter((group) => group.schoolyear_term && group.schoolyear_term['name'] === this.currentSchoolYearBegin.toString());
        }
        return filteredGroups;
    }

    public get currentSchoolYearBegin(): string {
        // year begin 1er août and finish 31 juillet
        const month = (new Date()).getMonth();
        const year = (new Date()).getFullYear();
        // 1 aout => 31 december
        if (month > 6 && month < 12) {
            return year.toString();
        }
        // december to aout exclude
        if (month < 7) {
            return (year - 1).toString();
        }
    }

    public loadPaginatedAssignments(filterOptions: CollectionOptionsInterface = {}): Observable<DataEntity[]> {
        // remove auto assignments for learners
        if (this.settings.hideLearnerAutoAssignments && this.authService.isLearner()) {
            filterOptions['filter']['removeLearnerAutoAssignment'] = true;
        }
        if (filterOptions['filter'].hasOwnProperty('assignated_node_title')) {
            filterOptions.urlExtension = filterOptions['filter'].assignated_node_title; //
            delete filterOptions.filter['assignated_node_title'];// replace title filter by urlExtension to use global search on backoffice selected fields
        }
        this.assignmentsPaginated = this.octopusConnect.paginatedLoadCollection('assignation_search', filterOptions);
        return this.assignmentsPaginated.collectionObservable.pipe(map(collection => collection.entities));
    }

    /**
     * launchAssignement if user is allowed to open lesson and return true or false
     * @param assignment : use to get info on lesson assigned to compare with licence
     * @param reloadAssignmentBefore : if true, assignment will be reload before the launch
     */
    public launchAssignment(assignment: DataEntity, reloadAssignmentBefore = false): Observable<boolean> {
        const obs = reloadAssignmentBefore ? this.loadAndGetAssignmentById(assignment.id) : of(assignment);
        return obs.pipe(
            map(mayBeLoadedAssignment => {
                if (this.isAllowedToOpenLesson(mayBeLoadedAssignment)) {
                    this.currentAssignment = mayBeLoadedAssignment;
                    this.communicationCenter
                        .getRoom('assignment')
                        .next('current', mayBeLoadedAssignment);

                    this.communicationCenter
                        .getRoom('assignation')
                        .next('event', {id: mayBeLoadedAssignment.id, event: 'start'});

                    return true;
                } else {
                    return false;
                }
            })
        );
    }

    public loadAndGetAssignmentById(assignmentId: string | number): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('assignation_search', +assignmentId);
    }

    public saveAssignment(assignment: DataEntity): void {
        const entity = _.cloneDeep(assignment);
        entity.type = 'assignations';
        this.savePending[entity.id] = entity.save();
        this.communicationCenter
            .getRoom('assignment')
            .next('saving', true);
        this.savePending[entity.id].pipe(take(1)).subscribe((save: DataEntity) => {
            delete this.savePending[entity.id];
            let queued;

            if (this.saveQueue[save.id]) {
                queued = this.saveQueue[save.id];
                delete this.saveQueue[save.id];

                const diff = queued.getDiff();
                for (const key in diff) {
                    save.set(key, diff[key]);
                }
            }

            this.communicationCenter
                .getRoom('assignment')
                .next('current', save);
            this.communicationCenter
                .getRoom('assignment')
                .next('savingDone', true);
            this.communicationCenter
                .getRoom('assignment')
                .next('saving', false);

            if (queued) {
                this.saveAssignment(queued);
            }
        });
    }

    public hasCompletionDate(type: string): boolean {
        return this.settings.hasCompletionDate
            && (!this.settings.completionDate.length
                || (this.settings.completionDate.length
                    && (
                        this.settings.completionDate.includes(type)
                        || this.settings.completionDate.includes('*')
                    )
                )
            );
    }

    public hasCompletionStartDateOnly(type: string): boolean {
        return this.settings.hasCompletionDate
            && this.settings.completionStartDateOnly.length
            && (
                this.settings.completionStartDateOnly.includes(type)
                || this.settings.completionStartDateOnly.includes('*')
            );
    }

    public hasCompletionTime(type: string): boolean {
        return this.settings.hasCompletionDate
            && this.settings.hasCompletionTime
            && (!this.settings.completionTime.length
                || (this.settings.completionTime.length
                    && (
                        this.settings.completionTime.includes(type)
                        || this.settings.completionTime.includes('*')
                    )));
    }

    public hasGrade(type: string): boolean {
        return this.settings.enableGradeType.length
            && this.settings.enableGradeType.includes(type);
    }

    public createAssignment(assignment): Observable<DataEntity[]> {
        const project = assignment.project;
        let startTime = 0;
        let dueTime = 0;
        const forceTraining = this.settings.hasType === false || !assignment?.type;

        const assignmentType = forceTraining ? 'training' : assignment.type.label;
        if (this.hasCompletionDate(assignmentType) || this.hasCompletionStartDateOnly(assignmentType)) {
            const startMoment = assignment.startDate;
            const dueMoment = assignment.dueDate;

            if (this.hasCompletionTime(assignmentType)) {
                const start = assignment.startTime.split(':').map(element => +element);
                startMoment.add(start[0], 'hours');
                startMoment.add(start[1], 'minutes');

                const due = assignment.dueTime.split(':').map(element => +element);
                dueMoment.add(due[0], 'hours');
                dueMoment.add(due[1], 'minutes');
            } else {
                if (this.hasCompletionDate(assignmentType)) {
                    dueMoment.add(23, 'hours');
                    dueMoment.add(59, 'minutes');
                }
            }

            startTime = Math.floor(new Date(startMoment).getTime() / 1000);
            if (this.hasCompletionDate(assignmentType)) {
                dueTime = Math.floor(new Date(dueMoment).getTime() / 1000);
            }
        }

        const assignations = [];
        const obs: Observable<DataEntity>[] = [];
        const assignmentTypeTraining = this.types.find((type) => type.label === 'training');
        assignment.learners?.forEach((learner) => {
            const assignmentsData = {
                assignator: +this.userData.id,
                assignated_user: learner.id,
                assignated_node: assignment.nodeId,
                dates: {
                    value: startTime,
                    value2: dueTime
                },
                state_term: this.getState('assigned'),
                type_term: forceTraining ? assignmentTypeTraining.id : +assignment.type.id,
                rating_base: forceTraining ? null : assignment.rating_base,
                assignatedCount: assignment.assignatedCount ? assignment.assignatedCount : null,
                comment: assignment.comment ? assignment.comment : null,
                title: assignment.title ? assignment.title : null,
                config: assignment.selectedActivities ? JSON.stringify({[assignment.selectedLesson.id]: assignment.selectedActivities}) : null,
                groups: assignment.groups
            };
            if (!assignmentsData.type_term) {
                if (+assignmentsData.assignator === +assignmentsData.assignated_user) {
                    assignmentsData.type_term = this.types.find((state) => state.label === 'auto');
                } else {
                    assignmentsData.type_term = this.types.find((state) => state.label === 'training');
                }
            }
            const assignmentObs = this.createEntityAssignment(assignmentsData);

            assignmentObs.subscribe((assignmentEntity: DataEntity) => {
                assignations.push(assignmentEntity.id);
                this.sendNotificationAfterCreateAssignment(assignmentEntity.get('assignated_node'), assignmentEntity, learner, project);

                // Ferme les assignations du même type sur le même parcours pour l'élève => TODO à transférer au back
                this.loadPaginatedAssignments({
                    filter: {
                        assignated_user: learner.id,
                        assignated_lesson_id: assignment.nodeId,
                        assignments_type: assignmentEntity.get('type_term').id,
                    },
                    page: 1,
                    range: 10,
                }).pipe(take(1))
                    .subscribe((assignments) => {
                        assignments.forEach((assignment) => {
                            if (+assignment.id !== +assignmentEntity.id && assignment.get('state_term').label !== 'closed') {
                                assignment.type = 'assignations';
                                assignment.set('state_term', this.getState('closed'));
                                assignment.save();
                            }
                        });
                    });
            });

            obs.push(assignmentObs);
        });

        const dataEntityArrayObs = combineLatest(...obs);

        dataEntityArrayObs.subscribe(dataArray => {
            this.octopusConnect.createEntity('assignations-group', {
                assignations,
                dates: {
                    value: startTime,
                    value2: dueTime
                },
                date: dueTime,
                type_term: forceTraining ? assignmentTypeTraining.id : +assignment.type.id,
                rating_base: forceTraining ? null : assignment.rating_base,
                group: assignment.group.map(g => g.id)
            }).pipe(take(1));
        });

        return dataEntityArrayObs;
    }

    /**
     *
     * @param date
     * @returns {string}
     */
    public localeDate(date: number): string {
        return localizedDate(date);
    }

    /**
     * return the scholar year
     */
    public schoolYears(startDate: number): string {
        const date = new Date(startDate * 1000);
        // year begin 1er août and finish 31 juillet
        const month = date.getMonth();
        const year = date.getFullYear();
        let yearBegin: number = null;
        // 1 aout => 31 december
        if (month > 6 && month < 12) {
            yearBegin = year;
        }
        // december to aout exclude
        if (month < 7) {
            yearBegin = year - 1;
        }
        return yearBegin + '-' + (yearBegin + 1);
    }

    /**
     *
     * @param date
     * @returns {string}
     */
    public localeTime(date: number): string {
        return localizedTime(date);
    }

    public loadAssignationsTypes(): Observable<any[]> {
        return this.octopusConnect.loadCollection('variables/instance').pipe(
            map((collection: DataCollection) => collection.entities[0].get('assignationTypes')),
            take(1));
    }

    /**
     * Obtain list of educational levels
     */
    public getEducationalLevels(): Observable<TypedDataEntityInterface<{}>[]> {
        return this.octopusConnect.loadCollection('educational_level').pipe(map(collection => <TypedDataEntityInterface<{}>[]> collection.entities));
    }

    public getConcepts(): Observable<TypedDataEntityInterface<{}>[]> {
        return this.octopusConnect.loadCollection('concepts').pipe(map(collection =><TypedDataEntityInterface<{}>[]> collection.entities));
    }

    public getChapters(): Observable<TypedDataEntityInterface<{}>[]> {
        return this.octopusConnect.loadCollection('chapters').pipe(map(collection =><TypedDataEntityInterface<{}>[]> collection.entities));
    }

    public deleteAssignment(assignment: DataEntity): Observable<boolean> {
        const entity = new DataEntity('assignations', assignment.attributes, this.octopusConnect, assignment.id);
        return entity.remove().pipe(
            filter(success => success),
            tap(() => this.communicationCenter
                .getRoom('assignment')
                .next('unassign', assignment.get('assignated_node'))
            )
        );
    }

    public loadAndGetAssignmentByLessonId(lessonId: string | number, filters: FilterData = {}): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('assignation_search', {assignated_lesson_id: lessonId, ...filters});
    }

    public loadAndGetAssignmentByLessonIdPaginated(lessonId: string | number, options: Partial<CollectionOptionsInterface> = {}): Observable<DataCollection> {

        const defaultOptions: Partial<CollectionOptionsInterface> = {
            filter: {assignated_lesson_id: lessonId}
        }

        return this.octopusConnect.paginatedLoadCollection('assignation_search', {...defaultOptions, ...options}).collectionObservable;
    }

    public loadSchoolyears(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('schoolyears').pipe(
            map(collection => collection.entities),
            take(1)
        );
    }

    public translationTabLabelListByRole(): string {
        return this.authService.isAtLeastTrainer() ? 'assignment.followed_data_trainer' : 'assignment.followed_data';
    }

    public translationTabLabelLogBookByRole(): string {
        return this.authService.isAtLeastTrainer() ? 'assignment.followed_diary_trainer' : 'assignment.followed_diary';
    }

    public loadAssignmentsGroup(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('assignations-group').pipe(map((collection) => collection.entities));
    }

    public loadPaginatedAssignmentGroup(filterOptions = {}): Observable<DataEntity[]> {
        const assignmentGroupListPaginated = this.octopusConnect.paginatedLoadCollection('assignations-group', filterOptions);
        const assignmentsGroupListPaginatedObs = assignmentGroupListPaginated.collectionObservable.pipe(map(collection => collection['entities']));

        return assignmentsGroupListPaginatedObs;
    }

    private loadPaginatedAssignmentGroupList(filterOptions = {}, getForDashboard = false): void {
        if (getForDashboard) {
            this.loadAssignationsTypes().pipe(take(1)).subscribe((assignationsTypes) => {
                this.types = assignationsTypes;
                const trainingType = this.types.find((state) => state.label === 'training');
                const homeworkType = this.types.find((state) => state.label === 'homework');
                const assessmentType = this.types.find((state) => state.label === 'assessment');

                const optionsForAssessment: CollectionOptionsInterface = {
                    filter: {
                        inprogress: 1,
                        type_term: assessmentType ? assessmentType.id : null
                    },
                    orderOptions: [{
                        field: 'endDate',
                        direction: OrderDirection.ASC
                    }]
                };

                const optionsForHomeworkType: CollectionOptionsInterface = {
                    filter: {
                        inprogress: 1,
                        type_term: homeworkType ? homeworkType.id : null
                    },
                    orderOptions: [{
                        field: 'endDate',
                        direction: OrderDirection.ASC
                    }]
                };
                const optionsForTraining: CollectionOptionsInterface = {
                    filter: {
                        inprogress: 1,
                        type_term: trainingType ? trainingType.id : null
                    },
                    orderOptions: [{
                        field: 'startDate',
                        direction: OrderDirection.DESC
                    }]
                };

                if (filterOptions && filterOptions['filter'] && filterOptions['filter']['group']) {
                    optionsForAssessment.filter.group = filterOptions['filter']['group'];
                    optionsForHomeworkType.filter.group = filterOptions['filter']['group'];
                    optionsForTraining.filter.group = filterOptions['filter']['group'];
                }

                const obsTraining = this.octopusConnect
                    .paginatedLoadCollection('assignations-group', optionsForTraining)
                    .collectionObservable;
                const obsAssessment = this.octopusConnect
                    .paginatedLoadCollection('assignations-group', optionsForHomeworkType)
                    .collectionObservable;
                const obsHomework = this.octopusConnect
                    .paginatedLoadCollection('assignations-group', optionsForAssessment)
                    .collectionObservable;

                combineLatest([obsAssessment, obsHomework, obsTraining])
                    .subscribe((data) => {
                        const assignmentsGroupSort = data[0].entities.concat(...data[1].entities).sort((a, b) => a.get('endDate') - b.get('endDate'));
                        assignmentsGroupSort.push(...data[2].entities);

                        const assignmentsGroup = assignmentsGroupSort.slice();
                        this.communicationCenter
                            .getRoom('assignation')
                            .next('assignmentsGroup', {
                                assignmentsGroup,
                                callback: (options, forDashboard) => this.loadPaginatedAssignmentGroupList(options, forDashboard)
                            });
                    });
            });

        } else {
            const assignmentGroupListPaginated = this.octopusConnect.paginatedLoadCollection('assignations-group', filterOptions);
            const assignmentsGroupListPaginatedObs = assignmentGroupListPaginated.collectionObservable.pipe(map(collection => collection.entities));

            assignmentsGroupListPaginatedObs.subscribe((assignmentGroupList: DataEntity[]) => {
                const assignmentsGroup = assignmentGroupList.slice();
                this.communicationCenter
                    .getRoom('assignation')
                    .next('assignmentsGroup', {assignmentsGroup, callback: (options) => this.loadPaginatedAssignmentGroupList(options)});
            });
        }
    }

    private loadAssignments(): void {
        const assignedState = this.states.find((state) => state.get('label') === 'assigned');
        const trainingType = this.types.find((state) => state.label === 'training');
        const assessmentType = this.types.find((state) => state.label === 'assessment');
        const homeworkType = this.types.find((state) => state.label === 'homework');
        const optsTraining = {
            filter: {
                'assignated_user': this.authService.userData.id,
                assignments_state: assignedState ? assignedState.id : null,
                assignments_type: trainingType.id
            }
        };
        const optsAssessment = {
            filter: {
                'assignated_user': this.authService.userData.id,
                assignments_state: assignedState ? assignedState.id : null,
                assignments_type: assessmentType.id
            }
        };
        const optsHomework = {
            filter: {
                'assignated_user': this.authService.userData.id,
                assignments_state: assignedState ? assignedState.id : null,
                assignments_type: homeworkType.id
            }
        };

        const obsTraining = this.octopusConnect
            .paginatedLoadCollection('assignation_search', optsTraining)
            .collectionObservable;
        const obsAssessment = this.octopusConnect
            .paginatedLoadCollection('assignation_search', optsAssessment)
            .collectionObservable;
        const obsHomework = this.octopusConnect
            .paginatedLoadCollection('assignation_search', optsHomework)
            .collectionObservable;

        combineLatest([obsAssessment, obsHomework, obsTraining])
            .subscribe((data: [any, any, any]) => {
                let assignments = data[0].entities.concat(...data[1].entities, ...data[2].entities);
                const dateNow = Math.floor(Date.now() / 1000);

                assignments = assignments.filter((assignment) => {
                    const startDate = +assignment.get('dates').value;
                    const endDate = +assignment.get('dates').value2;

                    return ((startDate && startDate <= dateNow) || !startDate) && ((endDate && endDate > dateNow) || !endDate);
                });
                this._dataSource.data = assignments.slice(0, 3);
            });
    }

    private loadAssignmentsByRole(): any {
        if (this.authService.isAtLeastTrainer()) {
            const pendingState = this.states.find((state) => state.get('label') === 'pending');
            const options = {filter: {'assignator': this.userData.id, assignments_state: pendingState ? pendingState.id : null}};

            const obs = this.octopusConnect
                .paginatedLoadCollection('assignation_search', options)
                .collectionObservable;

            obs.subscribe((data) => {
                this.communicationCenter
                    .getRoom('assignment')
                    .getSubject('assignmentList')
                    .next(data.entities);
            });
        } else {
            const assignedState = this.states.find((state) => state.get('label') === 'assigned');
            const closedState = this.states.find((state) => state.get('label') === 'closed');

            const optsAssigned = {filter: {'assignated_user': this.userData.id, assignments_state: assignedState ? assignedState.id : null}};
            const optsClosed = {filter: {'assignated_user': this.userData.id, assignments_state: closedState ? closedState.id : null}};

            const obsAssigned = this.octopusConnect
                .paginatedLoadCollection('assignation_search', optsAssigned)
                .collectionObservable;
            const obsClosed = this.octopusConnect
                .paginatedLoadCollection('assignation_search', optsClosed)
                .collectionObservable;

            combineLatest([obsAssigned, obsClosed]).subscribe((data: [any, any]) => {
                this.communicationCenter
                    .getRoom('assignment')
                    .getSubject('assignmentList')
                    .next(data);
            });
        }
    }

    /**
     * create assignment entity with data provided
     * @param data
     */
    private createEntityAssignment(data): Observable<DataEntity> {
        return this.octopusConnect.createEntity('assignations', data).pipe(take(1));
    }

    private loadAssignationsStates() {
        return this.octopusConnect.loadCollection('assignation_state').pipe(
            map((collection: DataCollection) => collection.entities as TypedDataEntityInterface<{}>[]),
            take(1));
    }

    /***
     * check if user have the paper method licence
     * @param entity : use to get info on lesson assigned to compare with licence
     */
    private isAllowedToOpenLesson(entity: DataEntity): boolean {
        const assignated_node = entity.get('assignated_node');
        const assignated_nodes = entity.get('assignated_nodes');
        // multiassignement x lesson to check
        if (assignated_node === null && assignated_nodes.length > 0) {
            return this.isAllLessonsAllowed(assignated_nodes);
        }
        // mono assignment one lesson to check
        return this.isLessonAllowed(assignated_node);
    }

    private postLogout(): void {
    }

    private postAuthentication(): void {
        const learners = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList').pipe(filter(l => l !== null));
        const groups = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList');
        const workgroups = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('workgroupsList');
        const project = this.communicationCenter
            .getRoom('project-management')
            .getSubject('selectedProject');

        project.subscribe((selectedProject) => {
            this.selectedProject = selectedProject;
        });

        combineLatest([learners, groups, workgroups]).subscribe((data: [any, any, any]) => {
            this.rawLearnersList = data[0] ? data[0] : [];
            this.rawGroupsList = data[1];
            this.rawWorkgroupsList = data[2];
        });

        this.loadAssignationsStates()
            .subscribe(states => {
                this.states = states;
                this.communicationCenter.getRoom('assignments').next('statesList', this.states);
                this.loadAssignationsTypes().subscribe((data) => {
                    this.communicationCenter.getRoom('assignments').next('assignmentTypes', data);
                    this.types = data;
                    if (this.authService.isLearner()) {
                        this.loadAssignments();
                    }
                });
                this.loadAssignmentsByRole();

            });
    }

    private queueSave(assignment: DataEntity): void {
        if (!this.saveQueue[assignment.id]) {
            this.saveQueue[assignment.id] = assignment;
        } else {
            const queuedAssignment = this.saveQueue[assignment.id];
            const diff = assignment.getDiff();

            for (const key in diff) {
                queuedAssignment.set(key, diff[key]);
            }
        }
    }

    /**
     * create assignement using communication center with a callback when finish
     */
    private createAssignmentWithCallBack(options: {
        assignment: { [key: string]: any },
        callback: (arg?) => any,
        /** @param dontUseSpecificConfiguration in createAssignment we use lot of settings and we dont want those settings for creation of an assignment */
        dontUseSpecificConfiguration?: boolean
    }): void {
        if (options && options.dontUseSpecificConfiguration) {
            this.createEntityAssignment(options.assignment).pipe(
                tap((assignment: DataEntity) => options.callback(assignment))
            ).subscribe();
        } else {
            this.createAssignment(options.assignment).subscribe(assignments => {
                options.callback(assignments[0]);
            });
        }
    }

    /**
     * create assignement using communication center with a callback when finish
     * used by assignation by step component new version for make an assignment
     */
    private createAssignmentWithCallBackV2(data: {
        assignment: IAssignmentByStepResult,
        callback: (arg?) => any
    }): void {
        if (data) {
            this.createAssignmentV2(data.assignment).pipe(
                tap((assignment: DataEntity) => data.callback(assignment))
            ).subscribe();
        }
    }

    public createAssignmentV2(data: IAssignmentByStepResult): Observable<DataEntity> {
        return <any>this.octopusConnect
            .createEntity('assignations-batch', {
                learners: data.learners,
                groups: data.groups,
                lesson: data.lesson,
                state: data.state,
                type: data.type,
                startDate: data.startDate,
                endDate: data.endDate,
                sublessons: data.sceances.filter(s => s.selected === true).map(sc => +sc.value)
                // comment and sceance later
            }).pipe(take(1));
    }

    /**
     * send notification to learner after assignment created.
     * @private
     */
    private sendNotificationAfterCreateAssignment(assignated_node, assignment, learner, project): void {
        this.communicationCenter
            .getRoom('notifications')
            .next('sendNotification', {
                recipient: learner.id,
                type: 'NEW_ASSIGNATION',
                content: {
                    author: this.userData ? this.userData.get('label') : '',
                    id: assignment.id,
                    formName: assignated_node.title,
                    project: project ? project.id : null
                }
            });
    }

    private getState(stateName: string): number {
        return +(this.states.find((state: DataEntity) => state.get('label') === stateName).id);
    }

    /**
     * check if all lessons in multiassignement are allowed
     * @param assignated_nodes : array of assignated node with id title and type
     */
    private isAllLessonsAllowed(assignated_nodes: any[]): boolean {
        for (let i = 0; i < assignated_nodes.length; i++) {
            if (!this.isLessonAllowed(assignated_nodes[i])) {
                // one or more lesson are not allowed => exit for all
                return false;
            }
        }
        // all lesson are allowed return true
        return true;
    }

    /**
     * check if licencing is activate for this assignated_node
     * if it is check if user has the right to open it
     * @param assignated_node : assignated_node with id type title
     */
    private isLessonAllowed(assignated_node): boolean {
        if (this.settingsLicensing['restrict'].includes(assignated_node.type)) {
            if (assignated_node.chapters.length === 0) {
                throw new Error('Not implemented : assigned node must have chapters');
            } else if (!this.learnerMethods.includes(assignated_node.chapters[0].id)) {
                this.openMethodModalRedirect(assignated_node.chapters[0].label);
                return false;
            }
        }
        return true;
    }

    private openMethodModalRedirect(methodName: string): void {
        this.setTranslateValueForMethodModalRedirect();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            titleDialog: this.methodDialogInfos.title,
            bodyDialog: this.methodDialogInfos.body + methodName,
            labelTrueDialog: this.methodDialogInfos.ok
        };
        const dialogRedirect = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
        dialogRedirect.afterClosed().subscribe(() => {
            this.router.navigate(['profile/licensing']);
        });
    }

    private setTranslateValueForMethodModalRedirect(): void {
        this.translate.get('assignment.method_modal.title').subscribe((translation: string) => this.methodDialogInfos.title = translation);
        this.translate.get('assignment.method_modal.body').subscribe((translation: string) => this.methodDialogInfos.body = translation);
        this.translate.get('assignment.method_modal.button.ok').subscribe((translation: string) => this.methodDialogInfos.ok = translation);
    }

    private deleteLessonAssignments(lessonId: string | number): Observable<boolean[]> {
        return this.loadAndGetAssignmentByLessonId(lessonId).pipe(
            take(1),
            mergeMap(dataCollection => combineLatest(
                dataCollection.entities.map(
                    entity => this.deleteAssignment(entity)
                )
            ))
        );
    }

    public removeMultipleAssignment(ids: number[]): Observable<boolean> {
        const entities = ids.map((id) => new DataEntity('assignations', {}, this.octopusConnect, id));
        const obs = entities.map((entity) => this.octopusConnect.deleteEntity(entity));
        return combineLatest(obs).pipe(
            mergeMap(() => of(true))
        );
    }


    private saveProgressInSubLesson(assignmentProgressData: { id: string, score: number, scoreByActivities: { [key: string]: number }, changed?: number }): void {
        if (this.currentAssignment) {
            const config = JSON.parse(this.currentAssignment.get('config')) || {};
            config[assignmentProgressData.id] = {
                score: assignmentProgressData.score,
                scoreByActivities: assignmentProgressData.scoreByActivities,
                changed: Math.floor(new Date().getTime() / 1000)
            };
            this.currentAssignment.set('changed', Math.floor(new Date().getTime() / 1000));
            this.currentAssignment.set('config', JSON.stringify(config));
            if (this.savePending[this.currentAssignment.id]) {
                this.queueSave(this.currentAssignment);
            } else {
                this.saveAssignment(this.currentAssignment);
            }
        }
    }

    private isEditable(lessonId: string): Observable<boolean> {
        return this.loadAndGetAssignmentByLessonIdPaginated(lessonId, {range: 1}).pipe(
            take(1),
            map((collection) => collection.entities.length === 0)
        )
    }
}
