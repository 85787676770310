import {inject, Injectable} from '@angular/core';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs';

export interface Nps {
    uid: string;
    value: number;
}
@Injectable({
    providedIn: 'root'
})
export class NpsService {
    private octopusConnect = inject(OctopusConnectService);
    constructor() { }

    saveNps(nps: Nps): Observable<DataEntity> {
        return this.octopusConnect.createEntity('nps', nps);
    }
}
