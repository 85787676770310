import {Injectable} from '@angular/core';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {Observable} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import {GroupManagementConfigurationService} from '@modules/groups-management/core/services/group-management-configuration.service';

@Injectable()
export class GroupsManagementService {
    /**
     * @deprecated must use GroupManagementConfigurationService
     */
    public settings: { [key: string]: any };
    /**
     * @deprecated must use GroupManagementConfigurationService
     */
    public settingsAccess: { [key: string]: any };
    headerTitle = 'navigation.groups-management';
    public activities: any[] = ['individuel', 'binôme', 'collectif', 'collaboratif'];
    public schoolYearsList: DataEntity[] = [];
    public schoolyearDefaultMatSelectValue = 'all';
    private alias: string;

    constructor(
        private communicationCenter: CommunicationCenterService,
        private octopusConnect: OctopusConnectService,
        private config: GroupManagementConfigurationService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                }
            });


        // TODO ne plus avoir de settings dans ce service mais utiliser le GroupManagementConfigurationService ou le AuthorizationService
        this.settings = this.config.settings;
        this.settingsAccess = this.config.settingsAccess;
    }

    public get gettingStarted(): string {
        return this.alias;
    }

    public set gettingStarted(alias: string) {
        this.alias = alias;
    }

    postAuthentication(): void {
    }

    public setHeaderTitle(title): void {
        this.headerTitle = title;
    }

    public getHeaderTitle(): string {
        return this.headerTitle;
    }

    /**
     * return the year where begin the current school year in regard of the current date
     */
    public currentSchoolYearBegin(): number {
        // year begin 1er août and finish 31 juillet
        const month = (new Date()).getMonth();
        const year = (new Date()).getFullYear();
        // 1 aout => 31 december
        if (month > 6 && month < 12) {
            return year;
        }
        // december to aout exclude
        if (month < 7) {
            return year - 1;
        }
    }

    loadSchoolyears(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('schoolyears').pipe(
            map(collection => collection.entities),
        );
    }

    /**
     * get list of school year entities
     */
    public getSchoolYearEntities(): DataEntity[] {
        return this.schoolYearsList;
    }

    /**
     * update schoolyear display
     */
    public displaySchoolYear(schoolyear: string): string {
        const nextSchoolyear = +schoolyear + 1;
        return schoolyear + '-' + nextSchoolyear;
    }

    public getSchoolyearTermId(year: number): number {
        const SchoolyearTermId = this.schoolYearsList.find((schoolYear) => schoolYear.get('name') === year.toString());
        return SchoolyearTermId ? +SchoolyearTermId.id : 0;
    }

    public getCurrentSchoolyearTermId(): string {
        return this.getSchoolyearTermId(this.currentSchoolYearBegin()).toString();
    }

    /**
     * Return a list of unaccepted routes for roles
     */
    public getForbiddenPaths(): { [role: string]: (string | RegExp)[] } {
        return this.settings.forbiddenPaths;
    }

    public isLinkTrainerMustBeDisplayed(): string[] {
        return this.config.isLinkTrainerMustBeDisplayed();
    }

    public isGroupMustBeAssociatedWithUsersInstitution(): boolean {
        return this.config.isGroupMustBeAssociatedWithUsersInstitution();
    }
}
