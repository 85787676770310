
import {LessonGranuleEntity} from '@modules/activities/core/models';
import {tap, mergeMap, take} from 'rxjs/operators';
import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {DataEntity} from 'octopus-connect';
import {combineLatest} from 'rxjs';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import * as _ from 'lodash-es';
import {DragulaService} from 'ng2-dragula';
import {isEqual} from 'lodash-es';
import {ActivityCreationService} from "@modules/activities/core/activity-creation.service";

@Component({
    selector: 'app-multi-editor',
    templateUrl: './multi-editor.component.html',
    providers: [DragulaService]
})
export class MultiEditorComponent implements OnInit {

    public formIsSaving = false;
    public renderIsReady = false;
    public multiActivityForm: UntypedFormGroup;
    public activities: DataEntity[] = [];

    private multiActivityGranule: LessonGranuleEntity;
    private activitiesInOriginalSort: number[];

    private activatedRoute= inject(ActivatedRoute);
    private activitiesService= inject(ActivitiesService);
    private formBuilder= inject(UntypedFormBuilder);
    private lessonService= inject(LessonsService);
    private activityCreationService= inject(ActivityCreationService);
    private route= inject(ActivatedRoute);
    private router= inject(Router);

    /**
     * Return true if a save is useful, false if useless.
     * Useful is defined because the title and/or the activities order is changed
     */
    public getIsUserShouldSave(): boolean {
        const activitiesOrderIsChanged = this.activities.map(a => +a.id).join(', ') !== this.activitiesInOriginalSort.join(', ');
        const titleIsChanged = this.multiActivityGranule.get('metadatas').title !== this.multiActivityForm.get('title').value;
        return activitiesOrderIsChanged || titleIsChanged;
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params) => {
            const subLessonGranuleId = params['subLessonId'];
            this.lessonService.reloadLesson(subLessonGranuleId).pipe(
                take(1),
                mergeMap((multiActivityGranule) => {
                    this.multiActivityGranule = multiActivityGranule;
                    return this.activitiesService.setActivitiesAfterLoaded(multiActivityGranule.get('reference')).pipe(tap(activities => {
                        this.activities.push(...activities);
                        this.activitiesInOriginalSort = this.activities.map(a => +a.id);
                    }));
                }))
                .subscribe(() => {
                    this.initialize();
                });
        });
    }

    /**
     * Save title & activities order
     */
    public onMultiActivityFormSubmit(): void {
        this.formIsSaving = true;
        this.multiActivityForm.disable();

        this.lessonService.saveFormOrLessonMetadata(this.multiActivityGranule.id, this.multiActivityForm.getRawValue(), 'lesson').pipe(
            take(1),
            mergeMap(() => this.lessonService.setActivitiesAsChildrenOfLesson(this.multiActivityGranule, this.activities, true))
        ).subscribe(() => {
            this.lessonService.reloadLesson(this.multiActivityGranule.id).pipe(
                take(1))
                .subscribe((lesson) => {
                    this.multiActivityGranule = lesson;
                    this.multiActivityFormInitialize();
                });
        });
    }

    /**
     * Return to the parent component (should be the first lesson editor component)
     */
    public goToParentLesson(): void {
        // TODO cette feature n'est pas géré par le nouvel editeur
    }

    /**
     * Generate an activity (typed by the multiactivity type),
     * set this new activity as child of this multiactivity,
     * redirect to the activity editor
     *
     * @remarks
     * Only 'multimedia' type are supported for now.
     */
    public createChildActivityAndRedirect(): void {
        const childActivityAllowedTypology = this.multiActivityGranule.get('metadatas').typology;

        if (childActivityAllowedTypology === undefined
            || childActivityAllowedTypology === null
            || isEqual(childActivityAllowedTypology, [])) {
            throw new Error('not implemented');
        }

        // TODO pas de any
        // Hack for use the already existing activity creating method
        const formattedTypo: any = _.cloneDeep(childActivityAllowedTypology);
        formattedTypo.type = formattedTypo.label;
        const obs = this.activityCreationService.createActivitiesByTypes([formattedTypo]);

        combineLatest(obs).pipe(mergeMap((activityGranules) =>
            combineLatest(activityGranules.map((activityGranule) =>
                this.lessonService.setActivityAsChildOfLesson(this.multiActivityGranule, activityGranule)
            ))
        )).subscribe((data) => {
            const updatedMultiActivity = data[0];
            const lastIndex = updatedMultiActivity.get('lesson_step').length - 1;
            const activityId = updatedMultiActivity.get('lesson_step')[lastIndex].id;
            this.router.navigate(['.', 'multimedia', activityId], {relativeTo: this.route});
        });
    }

    /**
     * Redirect to the editor of an activity
     * @param activityGranule to edit
     *
     * @remarks
     * Only multimedia activity are supported
     */
    public editActivity(activityGranule: DataEntity): void {
        this.router.navigate(['.', 'multimedia', activityGranule.id], {relativeTo: this.route});
    }

    /**
     * Return if user is allowed to edit activity
     *
     * @remarks
     * For the moment, only multimedia activities are editable
     *
     * @param activityGranule
     */
    public isEditableActivity(activityGranule: DataEntity): boolean {
        return activityGranule.get('metadatas').typology.label.toLowerCase() === 'multi';
    }

    /**
     * Prepare component when all data are loaded
     */
    private initialize(): void {
        this.multiActivityFormInitialize();
        this.renderIsReady = true;
    }

    /**
     * Generate the Form with default values and validators
     */
    private multiActivityFormInitialize(): void {
        this.multiActivityForm = this.formBuilder.group({
            title: [this.multiActivityGranule.get('metadatas').title, Validators.required],
        });

        this.formIsSaving = false;
    }
}
