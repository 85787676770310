import {Component, ViewChild} from '@angular/core';
import {OptionsComponent} from '@modules/activities/core/shared-components/options/options.component';
import {Observable, of} from 'rxjs';
import {BaseActivityComponent, TIME_DELAY_BEFORE_SAVE, TIME_DISPLAYING_CORRECTION} from '../base-activity.component';
import {AnswerResultInterface} from '@modules/activities/core/models';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {AnswerInterface} from '@modules/activities/core/models/answer.interface';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';
import {shuffle} from 'shared/utils/array';
import {TrueFalseActivityGranule} from '@modules/activities/core/models/activities/typologies/true-false-activity.granule';

@Component({
    selector: 'app-true-false',
    templateUrl: './true-false.component.html'
})

export class TrueFalseComponent extends BaseActivityComponent<TrueFalseActivityGranule> {
    @ViewChild(OptionsComponent, {static: true}) optionsComponent: OptionsComponent;

    // si this.displaySaveBtn === true, on attends une validation par clic sur un bouton "valider"
    // si this.displaySaveBtn === false, on attends une validation par clic sur une réponse ett donc passage automatique a l'activité suivante
    // this.displaySaveBtn sert aussi à savoir si on utilise des checkbox ou non (TODO: ce qui n'est pas top du tout et à modifier).
    public displaySaveBtn = false; // know if we display checkbox or button, data fetched from the api
    public isTwoColumns = false;
    public title: string;
    private waitUntilCorrectionFinished: boolean;
    public shouldDisplayAnswersLabel = false;


    private displayAnswersLabel(): boolean {
        const options = this.activitiesConfigurationService.activityOptions('trueFalse');
        return options ? options.displayAnswersLabel : false;
    }


    /**
     * Set the content data from given activity attributes
     * @param activityAttributes The attributes of the activity
     */
    protected setContentData(activityAttributes): void {
        this.setActivityConfiguration(activityAttributes.reference.config);
        this.setActivityGranuleDetails(activityAttributes.reference);

        this.setDefaultAnswersAvailable();
        if (!this.isActivityEmbedded) {
            this.loadUserSave();
        }

        if (this.displayForSummary) {
            this.seeAnswerSolution();
        }

        this.disableValidateButton();
    }

    /**
     * Set the activity configuration
     * @param config The configuration of the activity
     */
    protected setActivityConfiguration(config): void {
        this.shouldDisplayAnswersLabel = this.displayAnswersLabel();
        this.waitUntilCorrectionFinished = false;
        if (config) {
            this.isTwoColumns = config.doubleColumn !== 0;
            this.displaySaveBtn = config.displaySaveBtn === true;
        }
    }

    /**
     * Set the activity granule details
     * @param activityGranule The granule of the activity
     */
    protected setActivityGranuleDetails(activityGranule): void {
        this.referenceActivityGranule = activityGranule;
        this.instruction = this.referenceActivityGranule.instruction;
        this.wording = this.referenceActivityGranule.wording;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        const hideWordingAudioInPreview = this.preview && this.activitiesService.settings['hiddenFieldActivityPreview'].find(field => field === 'wordingAudio');
        this.wordingAudio = hideWordingAudioInPreview || !this.preview
            ? this.referenceActivityGranule.wordingAudio
            : '';
    }

    protected disableValidateButton(): void {
        // disable validate button enable it only if answer is made and only if the flag
        // displaySaveBtn is true no information on the use case of displaySaveBtn the code is recent so i let
        // the old behaviour except for the case of true false with data formated like i had in the example
        if (this.displaySaveBtn) {
            const button = this.buttons.find(b => b.type === 'validate');
            if (button) {
                button.disable = true;
            }
        }
    }


    /**
     * permet d'initialisé le tableau des réponses selectionnés
     * @param answers
     * @param resetSelectEd reset answer selected to false
     * @private
     */
    private setDefaultAnswersAvailable(): void {
        this.availableAnswers = shuffle(this.referenceActivityGranule.activity_content.answers.map((answer) => {
            return {
                id: answer.id,
                answer: answer.answer,
                image: answer.image,
                select: !!+answer.select,
                correct_answer: !!+answer.correct_answer,
                state: ItemAnswerStateEnum.pristine,
                feedback: answer.feedback ? answer.feedback : '',
                audio: answer.audio ? answer.audio : null,
                fid: answer.fid,
                flashcards: []
            };
        }), true);
    }

    protected reset(resetAllSubscribe: boolean = false, type = null): Observable<boolean> {
        this.waitUntilCorrectionFinished = false;
        if (this.displayForSummary && this.referenceActivityGranule) {
            this.seeAnswerSolution();
        }
        return super.reset(resetAllSubscribe, type);
    }

    protected setAnswer(): void {
        if (this.userSave?.get('userActivity')?.entitySave?.answers && !this.displayForSummary) {
            const answers = this.userSave.get('userActivity').entitySave.answers;
            this.availableAnswers.forEach((option: AnswerInterface, index: number) => {
                if (answers[index]) {
                    this.answersSelected = [answers[index]];
                }
            });
            if (!this.lessonsService.isTrainerSeeCorrection()) {
                this.checkAnswer();
                this.activitiesService.userAnswer.next(answers);
                this.testAnswer = true;
            }
        }
    }

    protected checkAnswer(): void {
        this.answerStatus = answerStatusEnum.missing;
        if (this.autoCorrection) {
            this.communicationCenter.getRoom('stepper').getSubject('locked').next(true);
            const answerResult: AnswerResultInterface = {
                id: +this.activity.id,
                state: ItemAnswerStateEnum.missing,
                isLast: undefined
            };
            this.answersSelected.forEach((option) => {
                if (option.correct_answer) {
                    option.state = ItemAnswerStateEnum.currentlyCorrect;
                    answerResult.state = ItemAnswerStateEnum.currentlyCorrect;
                    answerResult.isLast = true;
                } else {
                    option.state = ItemAnswerStateEnum.incorrect;
                    answerResult.state = ItemAnswerStateEnum.incorrect;
                }
            });
            super.manageProgressBarEventToSend(answerResult);
            // TODO faire une meilleure animation angular
            setTimeout(() => {
                this.answersSelected.filter(a => a.state === ItemAnswerStateEnum.incorrect).forEach(a => a.state = ItemAnswerStateEnum.pristine);
                this.answersSelected.filter(a => a.state === ItemAnswerStateEnum.currentlyCorrect).forEach(a => a.state = ItemAnswerStateEnum.wasCorrect);
                if (this.isActivityEmbedded) {
                    if (this.allAnswerCorrect) {
                        this.isActivityEmbeddedDone = true;
                        super.setFeedBackFromApi(null, answerStatusEnum.correct);
                    } else {
                        this.waitUntilCorrectionFinished = false;
                    }
                } else {
                    if (this.allAnswerCorrect) {
                        setTimeout(() => {
                            this.doAction('next', ['save']);
                            this.communicationCenter.getRoom('stepper').getSubject('locked').next(false);
                        }, TIME_DELAY_BEFORE_SAVE);
                    } else {
                        this.communicationCenter.getRoom('stepper').getSubject('locked').next(false);
                        this.waitUntilCorrectionFinished = false;
                    }
                }
            }, TIME_DISPLAYING_CORRECTION);
        } else {
            this.waitUntilCorrectionFinished = false;
        }

        if (this.answersSelected.length) {
            this.answerStatus = this.answersSelected.find((option: AnswerInterface) =>
                option.correct_answer) ? answerStatusEnum.correct : answerStatusEnum.wrong;
        }
        if (this.lessonsService.isLessonTraining() && !this.lessonsService.isAtLeastTrainer()) {
            this.displayFeedback = (this.answerStatus === 3)
                && this.activitiesService.settings.displayFeedback;
        }
        this.activitiesService.isUserAnswerStatus
            .next({status: this.answerStatus, index: this.activityStepIndex});
        this.communicationCenter.getRoom('stepper').getSubject('locked').next(true);
    }

    private get allAnswerCorrect(): boolean {
        return this.answersSelected.some((option: AnswerInterface) => !!+option.correct_answer);
    }

    /**
     * get the grade calculated with answer correctly answered
     */
    protected getGrade(): { newGrade: number, oldGrade: number } {
        return {
            newGrade: this.answersSelected.filter((answer: AnswerInterface) => answer.correct_answer).length,
            oldGrade: this.userSave ? this.userSave.get('userActivity').entitySave.answers
                    .filter((answer: AnswerInterface) => answer.correct_answer).length
                : 0
        };
    }

    /**
     * récupere dans la config de l'activité la mise en page.
     */
    public get columnClass(): string {
        return this.answersSelected && this.answersSelected.length > 3 ? 'columns-2' : 'columns-1';
    }

    public validate(option: AnswerInterface): void {
        if (option) {
            this.answersSelected = [{
                id: option.id,
                answer: option.answer,
                image: option.image,
                select: true,
                correct_answer: option.correct_answer,
                state: ItemAnswerStateEnum.pristine,
                feedback: option.feedback,
                audio: option.audio,
                fid: option.fid,
                flashcards: []
            }];
        }
        this.onOptionChange(true);
        // si option !== undefined, le call vient du clic pour selectionner la réponse "valider"
        // si option === undefined, le call vient du bouton "valider", ce qui veut dire que l'on lance la correction
        if (!option && this.autoCorrection && !this.waitUntilCorrectionFinished) {
            this.waitUntilCorrectionFinished = true;
            this.checkAnswer();
        }
    }

    public get valueChecked(): string {
        return this.answersSelected[0]?.answer || null;
    }

    public set valueChecked(value: string) {
        this.availableAnswers.forEach((item: AnswerInterface) => {
            if (value === item.answer) {
                this.answersSelected = [item];
            }
        });
    }

    public get isOptionDisabled(): boolean {
        if (this.isActivityEmbedded && this.isActivityEmbeddedDone) {
            return true;
        } else if (this.displaySaveBtn) {
            return this.answersSelected.some((answer) => answer.state === ItemAnswerStateEnum.wasCorrect || answer.state === ItemAnswerStateEnum.currentlyCorrect);
        } else {
            return this.displaySolution // solution displayed
                || this.testAnswer // answer tested
                || (this.answerSaved && this.lessonsService.isLessonEvaluation()) // answer saved and assignment is assessment or homework
                || this.lessonsService.isLessonCorrected(); // assignment exist and due date finished
        }
    }

    /**
     * sert aux css pour afficher l'état d'une reponse
     * TODO: on doit faire en sorte que les class css utilise le "state" d'une réponse cf: ItemAnswerInterface
     * pour permettre de retourner simplement le "state" qui sera la également la class css
     * @param option
     */
    public optionState(answer: AnswerInterface): string {
        const answerSelected = this.answersSelected?.find((item: AnswerInterface) => item.id === answer.id);
        if (answerSelected) {
            if (answerSelected.state === ItemAnswerStateEnum.pristine) {
                return 'selected';
            }
            return answerSelected.state;
        }
        return ItemAnswerStateEnum.pristine;
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        this.answersSelected = this.referenceActivityGranule.activity_content.answers
            .filter((answer) => !!+answer.correct_answer)
            .map((answer) => {
                return {
                    id: answer.id,
                    answer: answer.answer,
                    image: answer.image,
                    select: !!+answer.correct_answer,
                    correct_answer: !!+answer.correct_answer,
                    state: ItemAnswerStateEnum.wasCorrect,
                    feedback: answer.feedback ? answer.feedback : '',
                    audio: answer.audio ? answer.audio : null,
                    fid: answer.fid,
                    flashcards: []
                };
            });
        this.displaySolution = true;
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }
}

