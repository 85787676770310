import {Component, Input} from '@angular/core';
import { SortMatAnswer } from '@modules/activity-edition/core/features/activity-editor-panel/answerTypes';

@Component({
  selector: 'app-activity-edition-preview-sort-mat',
  templateUrl: './activity-edition-preview-sort-mat.component.html',
  styleUrls: ['./activity-edition-preview-sort-mat.component.scss']
})
export class ActivityEditionPreviewSortMatComponent {
    @Input() answersState!: SortMatAnswer;

}
